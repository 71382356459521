import * as React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Tabs, Tab, Typography, Badge, Hidden } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocationIcon, RegisterIcon } from '../../common/Icons';
import {
    Event, EventData, Portal, Contact, ContactDetails, Team, GolferGroup, Competition, AcceptedInvite, getCourseName, generateGroups,
    getHolesRange, Round, correctHandicapSystem, EventBase, isRound, fixLegacyRounds, ContactInvite, Tee, fixLegacyTees, rollEvents
} from '../../types/EventTypes';
import { generateTeeTimes } from '../TeeTimes';
import { eventBadgeUrl, everybodyIsScheduledEventOrRounds, everybodyIsPairedEventOrRounds, formatEventDateAndTime, golfersAvailableEventOrRounds, everybodyHasTees } from '../Event';
import { DEBUG, ITEM_NEW_EVENT_ID } from '../../util/config';
import * as Backend from '../../util/firebase';
import * as Utils from '../../util/utility';
import { pushUrl } from '../../redux/ReduxConfig';
import SettingsTab from '../tabs/settings/SettingsTab';
import GolfersTab from '../tabs/golfers/GolfersTab';
import ScoreList from '../tabs/scores/ScoreList';
import ToolsTab from '../tabs/tools/ToolsTab';
import { WithUserAware, useUserAware } from '../../auth/Auth';
import { FirebaseDocComponent, FirebaseDataComponent, FirebaseUserPubDataComponent, FirebaseUserDataComponent } from '../../common/WithData';
import { ErrorPage } from '../../main/ErrorBoundary';
import EventCreatedDialog from './EventCreatedDialog';
import { ArcStepper } from './ArcStepper';
import { useAppStyles, styles } from '../../styles';
import AppButton from 'src/common/components/AppButton';
import CompleteEventSetupDialog from './CompleteEventSetupDialog';
import { Flex, IconLinkElement } from 'src/common/Misc';
import { fixTeamsHandicapAndGender } from 'src/scoring/handicap';
import { EventRawDetails } from './EventRawDetails';
import { EventDrawer } from './EventDrawer';
import { WithStyles } from '@mui/styles';

type SelectedTab = 'settings' | 'golfers' | 'scores' | 'tools' | 'raw';

export interface GolferStats {
    golfersCount: number;
    allTeamsPaired: boolean;
    teamsCount: number;
    allScheduled: boolean;
    groupsCount: number;
    golfersAvailable: boolean;
    golfersSubLabel: string;
    teamsSubLabel: string;
    groupsSubLabel: string;
    invitesSubLabel: string;
}

function getGolferStats(eventData: EventData) {
    const golfersCount = Array.from(eventData.golfersAggregated.values()).filter(c => !c.hidden).length;
    const { allTeamsPaired, teamsCount } = everybodyIsPairedEventOrRounds(eventData);
    const { allScheduled, groupsCount } = everybodyIsScheduledEventOrRounds(eventData);
    const golfersAvailable = golfersAvailableEventOrRounds(eventData);
    const golfersSubLabel = Utils.withS(golfersCount, 'golfer');
    const teamsSubLabel = eventData.event.teamSize > 1 ? teamsCount > 0 ? Utils.withS(teamsCount, 'team') : 'not paired' : 'no teams';
    const groupsSubLabel = groupsCount > 0 ? Utils.withS(groupsCount, 'group') : 'not set';
    const invitesSubLabel = `${Array.from(eventData.invitedContacts.values()).length} invited`;
    const res: GolferStats = {
        golfersCount,
        allTeamsPaired,
        teamsCount,
        allScheduled,
        groupsCount,
        golfersAvailable,
        golfersSubLabel,
        teamsSubLabel,
        groupsSubLabel,
        invitesSubLabel
    };
    return res;
}

const EventShortInfo = (props: { eventData: EventData }) => {
    const classes = useAppStyles();
    const { eventData } = props;
    const { event, rounds, portal, golfersAggregated } = eventData;
    const [completeSetup, setCompleteSetup] = React.useState(0);
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    const linkRegistration = <IconLinkElement href={`/event/${event.publicId}`} target="portal">Registration page</IconLinkElement>;
    const linkTV = <IconLinkElement href={`/tv/${event.publicId}`} target="tv">TV Leaderboard</IconLinkElement>;
    let name = event.name;
    let extInfo = '' as React.ReactNode;
    if (DEBUG && event.legitDate) {
        name += ' (legit)';
    }
    if (DEBUG && event.legitAppDate) {
        name += ' (legit-app)';
    }
    if (event.deleted) {
        name += ' (deleted)';
    }
    const stepsTotal = eventData.event.teamSize > 1 ? 3 : 2;
    let participantsOk = false;
    let scheduleOk = false;
    let teesOk = false;
    let stepsCompleted = 0;
    const dataLoaded = eventData.golfersMap.size > 0 && (event.teamSize === 1 || eventData.teamsMap.size > 0) && eventData.groupsMap.size > 0;
    const { allTeamsPaired } = everybodyIsPairedEventOrRounds(eventData);
    if (dataLoaded && golfersAggregated.size > 0 && allTeamsPaired) {
        stepsCompleted++;
        participantsOk = true;
    }
    const { allScheduled, groupsCount } = everybodyIsScheduledEventOrRounds(eventData);
    if (dataLoaded && allScheduled && groupsCount > 0) {
        stepsCompleted++;
        scheduleOk = true;
    }
    if (dataLoaded && everybodyHasTees(eventData)) {
        stepsCompleted++;
        teesOk = true;
    }
    const today = Utils.getUserToday();
    const eventDate = event.date;
    if (today <= eventDate && eventDate < today + Utils.DAY_MILLIS && stepsCompleted === stepsTotal) {
        stepsCompleted++;
    }
    const clickComplete = () => {
        if (stepsCompleted < stepsTotal) {
            setCompleteSetup(1);
        } else if (stepsCompleted === stepsTotal) {
            setCompleteSetup(2);
        }
    }
    const clickStandings = () => pushUrl(`/event/${event.publicId}/standings`);
    return (
        <div className={classes.eventHeader}>
            <Grid container spacing={2} alignItems="center">
                <Hidden mdDown>
                    <Grid>
                        <ArcStepper
                            onClickSteps={clickComplete}
                            badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''}
                            stepsTotal={stepsTotal}
                            stepsCompleted={stepsCompleted}
                            size={80}
                        />
                    </Grid>
                </Hidden>
                <Grid flex={1}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid><Typography variant="h5">{name}</Typography></Grid>
                        <span color="inherit" style={{ flex: 1, fontSize: 12 }} />
                        <Grid>
                            {dataLoaded && stepsCompleted < stepsTotal &&
                                <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                            {dataLoaded && stepsCompleted === stepsTotal &&
                                <AppButton color="info" onClick={clickComplete}>Customize event</AppButton>}
                            {dataLoaded && stepsCompleted > stepsTotal &&
                                <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Hidden mdUp>
                            <Grid><Typography className={classes.link} noWrap>{linkRegistration}</Typography></Grid>
                            <Grid><Typography className={classes.link} noWrap>{linkTV}</Typography></Grid>
                        </Hidden>
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {formatEventDateAndTime(event, rounds, true, false)}
                                </Flex>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <LocationIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {getCourseName(event.course)}
                                </Flex>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <RegisterIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {Utils.withS(golfersAggregated.size, 'golfer') + ' registered'}
                                    {extInfo}
                                </Flex>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid>
                        <ArcStepper
                            onClickSteps={clickComplete}
                            badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''}
                            stepsTotal={stepsTotal}
                            stepsCompleted={stepsCompleted}
                            size={80}
                        />
                    </Grid>
                    <Grid xs={12} />
                </Hidden>
            </Grid>
            {completeSetup > 0 &&
                <CompleteEventSetupDialog
                    event={event}
                    participantsOk={participantsOk}
                    scheduleOk={scheduleOk}
                    teesOk={teesOk}
                    handleClose={eventSetupClosed}
                    customize={completeSetup === 2}
                />}
        </div>
    );
};

interface EventHeaderProperties {
    eventData: EventData;
    value: SelectedTab;
    onTabChange: (event: React.SyntheticEvent, value: SelectedTab) => void;
}

const EventHeader = (props: EventHeaderProperties) => {
    const classes = useAppStyles();
    const { onTabChange, value, eventData } = props;
    const golfersOk =
        golfersAvailableEventOrRounds(eventData) &&
        everybodyIsPairedEventOrRounds(eventData).allTeamsPaired &&
        everybodyIsScheduledEventOrRounds(eventData).allScheduled;
    const scoringOk = everybodyHasTees(eventData);
    return (
        <div>
            <EventShortInfo eventData={eventData} />
            <div className={classes.stickyTop} style={{ background: 'white', display: 'none' }}>
                <Tabs
                    value={value}
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    onChange={onTabChange}
                    className={classes.eventTabs}
                >
                    <Tab label="Settings" value="settings" />
                    <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={golfersOk}>Golfers &nbsp;</Badge>} value="golfers" />
                    <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={scoringOk}>Scoring &nbsp;</Badge>} value="scores" />
                    <Tab label="Tools" value="tools" />
                    <Tab label={<span style={{ color: 'white' }}>Raw</span>} value="raw" />
                </Tabs>
            </div>
        </div>
    );
};

type RenderInfoTabProps = { event: Event } & WithStyles<typeof styles>;
type RenderInfoState = EventData & { recent: Map<string, string> };

class RenderInfoTab extends React.Component<RenderInfoTabProps, RenderInfoState> {
    state: RenderInfoState = {
        event: this.props.event,
        recent: new Map(),
        portal: {} as Portal,
        portalLoaded: false,
        teamsMap: new Map(),
        teamsListMap: new Map(),
        groupsMap: new Map(),
        loadedGroupsMap: new Map(),
        golfersMap: new Map(),
        invitedContacts: new Map(),
        roster: new Map(),
        golfersAggregated: new Map(),
        competitionsMap: new Map(),
        acceptedInvites: new Map(),
        teeTimesMap: new Map,
        rounds: [],
        genTeeTimes: () => { },
        setSelectedRound: () => { }
    };

    componentDidMount() {
        this.setState({
            genTeeTimes: this.genTeeTimes,
            setSelectedRound: this.setSelectedRound
        });
    }

    componentDidUpdate(prevProps: Readonly<RenderInfoTabProps>) {
        if (this.props.event !== prevProps.event) {
            const event = this.props.event;
            this.setState({ event });
        }
    }

    private setSelectedRound = (selectedRound?: Round) => this.setState({ selectedRound });

    private onUserData = (adminEmail: string) => this.setState({ adminEmail });

    private latestEventOrRound(eventOrRoundId: string): EventBase | undefined {
        const { event } = this.props;
        const { rounds } = this.state;
        return eventOrRoundId === event.id ? event : rounds.find(round => eventOrRoundId === round.id);
    }

    private genTeeTimes = (eventOrRoundId: string) => {
        const eventOrRound = this.latestEventOrRound(eventOrRoundId);
        if (!eventOrRound) {
            return;
        }
        const { golfersMap, teamsMap, loadedGroupsMap, groupsMap, teeTimesMap } = this.state;
        if (eventOrRound.teamSize === 1 && loadedGroupsMap.size > 0 || (eventOrRound.teamSize > 1 && teamsMap.size > 0)) {
            const loadedGroups = loadedGroupsMap.get(eventOrRound.id) ?? [];
            const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
            const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
            const fixedGroups = generateGroups(eventOrRound, golfers, teams, loadedGroups);
            const holesRange = getHolesRange(eventOrRound.holesType);
            const teeTimes = generateTeeTimes(eventOrRound.teeTime, holesRange, fixedGroups.length);
            teeTimesMap.set(eventOrRound.id, teeTimes);
            groupsMap.set(eventOrRound.id, fixedGroups);
            //   this.setState({ teeTimesMap, groupsMap });
        }
    }

    private updateContactsFromRoster = () => {
        const { roster, golfersAggregated } = this.state;
        golfersAggregated.forEach(golferAggregated => {
            const contactInRoster = roster.get(golferAggregated.id)
            if (contactInRoster) {
                golferAggregated.email = contactInRoster.email;
                golferAggregated.phone = contactInRoster.phone;
                golferAggregated.notes = contactInRoster.notes;
            }
        })
    }

    private updateContacts = (fromRoster: boolean) => {
        const { event } = this.props;
        const { rounds, golfersMap, golfersAggregated } = this.state;
        if (!fromRoster) {
            const eventOrRounds = rollEvents(event, rounds);
            if (eventOrRounds.length > 0) {
                const golfers = golfersMap.get(eventOrRounds[0].id);
                if (golfers) {
                    golfers.forEach(golfer => {
                        golfersAggregated.set(golfer.id, { ...golfer, tee: undefined });
                    });
                    const hiddenGolfers = Array.from(golfersAggregated.values()).filter(golferAggregated => !golfers.has(golferAggregated.id));
                    hiddenGolfers.forEach(hiddenGolfer => golfersAggregated.delete(hiddenGolfer.id));
                }
            }
            golfersAggregated.forEach(golferAggregated => {
                const roundTees: { [s: string]: Tee; } = {};
                eventOrRounds.forEach(eventOrRound => {
                    const golfers = golfersMap.get(eventOrRound.id);
                    if (golfers && golfers.has(golferAggregated.id)) {
                        const roundGolfer = golfers.get(golferAggregated.id)!;
                        if (roundGolfer.tee) {
                            roundTees[eventOrRound.id] = roundGolfer.tee;
                        }
                    }
                });
                golferAggregated.roundTees = roundTees;
                golferAggregated.tee = undefined;
            })
        }
        this.updateContactsFromRoster();
        this.setState({ golfersAggregated });
    }

    private onRoster = (roster: Map<string, ContactDetails>) => {
        this.setState({ roster }, () => this.updateContacts(true));
    }

    private onGolfers = (eventOrRoundId: string, golfers: Map<string, Contact>) => {
        const { golfersMap } = this.state;
        golfersMap.set(eventOrRoundId, golfers);
        this.updateContacts(false);
        this.genTeeTimes(eventOrRoundId);
    }

    private onInvitedList = (invited: Map<string, ContactInvite>) => {
        const filteredInvites: Map<string, ContactInvite> = new Map<string, ContactInvite>();
        invited.forEach((value, key) => {
            if (value.inviteStatus != 'confirmed') {
                filteredInvites.set(key, value);
            }
        });
        this.setState({ invitedContacts: filteredInvites });
    }

    private onTeams = (eventOrRoundId: string, teamsList: Array<Team>, teams: Map<string, Team>) => {
        const { golfersMap, teamsMap, teamsListMap } = this.state;
        const golfers = golfersMap.get(eventOrRoundId) ?? new Map<string, Contact>();
        const golferTeam = new Map<string, Team>();
        fixTeamsHandicapAndGender(teams, golfers);
        teamsList.push({ id: '', contactIds: [], order: teamsList.length });
        teams.forEach(team => team.contactIds.forEach(golferId => golferTeam.set(golferId, team)));
        teamsMap.set(eventOrRoundId, teams);
        teamsListMap.set(eventOrRoundId, teamsList);
        this.genTeeTimes(eventOrRoundId);
    }

    private onGroups = (eventOrRoundId: string, groups: Array<GolferGroup>) => {
        const { loadedGroupsMap } = this.state;
        loadedGroupsMap.set(eventOrRoundId, groups);
        this.genTeeTimes(eventOrRoundId);
    }

    private fixLegacyTees = (baseEventId: string, competitions: Array<Competition>) => {
        const { event } = this.props;
        if (baseEventId === event.id) {
            fixLegacyTees(event, competitions);
        }
    }

    private onCompetitions = (eventOrRoundId: string, competitions: Array<Competition>) => {
        const eventOrRound = this.latestEventOrRound(eventOrRoundId);
        if (!eventOrRound) {
            return;
        }
        const { competitionsMap } = this.state;
        competitionsMap.set(eventOrRoundId, competitions);
        competitions.forEach(competition => competition.eventOrRoundId = eventOrRoundId);
        if (isRound(eventOrRound)) {
            competitions.forEach(competition => competition.roundOrder = eventOrRound.roundOrder);
        }
        this.fixLegacyTees(eventOrRound.id, competitions)
    }

    private onRounds = (rounds: Array<Round>) => {
        let { selectedRound } = this.state;
        fixLegacyRounds(rounds);
        rounds.sort((a, b) => a.roundOrder - b.roundOrder);
        selectedRound = rounds.find(r => r.id === selectedRound?.id);
        if (!selectedRound && rounds.length > 0) {
            selectedRound = rounds[0];
        }
        this.setState({ rounds, selectedRound });
        rounds.forEach(round => this.genTeeTimes(round.id));
    }

    render() {
        const { event, classes } = this.props;
        const { recent, ...eventData } = this.state;
        const allEvents = event.type === 'multiday' ? [event, ...eventData.rounds] : [event];
        const events = event.type === 'multiday' ? eventData.rounds : [event];
        const parentUrl = `/events/${event.id}`;
        const onSelectedTab = (t: string, sub: string) => recent.set(t, sub);
        const pathName = location.pathname.replace(parentUrl + '/', '').replace(parentUrl, '') || 'settings';
        const tab = (pathName.indexOf('/') > 0 ? pathName.substring(0, pathName.indexOf('/')) : pathName) as SelectedTab;
        const golferStats = getGolferStats(eventData);
        const changeTab = (_: React.ChangeEvent<{}>, value: SelectedTab) => pushUrl(`${parentUrl}/${value}`);
        return (
            <div style={{ display: 'flex' }}>
                <EventDrawer event={event} golferStats={golferStats} />
                <div style={{ width: '100%' }}>
                    <EventHeader eventData={eventData} value={tab} onTabChange={changeTab} />
                    <div className={classes.listRootGrey}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '135%', textTransform: 'uppercase' }}>
                            {tab}
                        </Typography>
                    </div>
                    <Routes>
                        <Route
                            path="settings/*"
                            element={<SettingsTab eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
                        />
                        <Route
                            path="golfers/*"
                            element={<GolfersTab
                                eventData={eventData}
                                initial={recent.get(tab)}
                                golferStats={golferStats}
                                onSelectedTab={subtab => onSelectedTab(tab, subtab)}
                            />}
                        />
                        <Route
                            path="scores/*"
                            element={<ScoreList eventData={eventData} />}
                        />
                        <Route
                            path="tools/*"
                            element={<ToolsTab eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab) ?? 'print'} />}
                        />
                        <Route
                            path="raw/*"
                            element={<EventRawDetails eventData={eventData} />}
                        />
                        <Route
                            path="/*"
                            element={<Navigate to={'settings'} replace />}
                        />
                    </Routes>
                </div>
                <FirebaseDocComponent
                    onDoc={doc => this.setState({ portal: Backend.fromEntity<Portal>(doc), portalLoaded: true })}
                    docReference={Backend.portalFields(event.id)} />
                <FirebaseDataComponent
                    name="invites"
                    query={Backend.acceptedInvitesCollection(event.userId)}
                    onMap={(acceptedInvites: Map<string, AcceptedInvite>) => this.setState({ acceptedInvites })} />
                <FirebaseDataComponent
                    name="roster"
                    query={Backend.rosterDb(event.userId)}
                    onMap={this.onRoster} />
                {<FirebaseDataComponent<ContactInvite>
                    key={event.id}
                    name="invited"
                    queryPath={event.id}
                    query={Backend.eventInvitedQuery(event.id)}
                    onMap={invitedList => this.onInvitedList(invitedList)} />}
                {allEvents.map(eventOrRound => <FirebaseDataComponent<Contact>
                    key={eventOrRound.id}
                    name="golfers"
                    queryPath={eventOrRound.id}
                    query={Backend.eventGolfersQuery(eventOrRound.id)}
                    onMap={golfers => this.onGolfers(eventOrRound.id, golfers)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<Competition>
                    key={eventOrRound.id}
                    name="competitions"
                    queryPath={eventOrRound.id}
                    query={Backend.eventCompetitionsQuery(eventOrRound.id)}
                    onData={competitions => this.onCompetitions(eventOrRound.id, competitions)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<Team>
                    key={eventOrRound.id}
                    name="teams"
                    queryPath={eventOrRound.id}
                    query={Backend.eventTeamsQuery(eventOrRound.id)}
                    onDataMap={(teamsList, teams) => this.onTeams(eventOrRound.id, teamsList, teams)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<GolferGroup>
                    key={eventOrRound.id}
                    name="groups"
                    queryPath={eventOrRound.id}
                    query={Backend.golferGroupDb(eventOrRound.id)}
                    onData={groups => this.onGroups(eventOrRound.id, groups)} />)}
                {event.type === 'multiday' && <FirebaseDataComponent
                    name="rounds"
                    queryPath={event.id}
                    query={Backend.eventRoundsQuery(event.id)}
                    onData={this.onRounds} />}
                <FirebaseUserDataComponent onData={data => this.onUserData(data.email)} />
                <FirebaseUserPubDataComponent
                    uid={event.userId}
                    onData={data => this.setState({ units: data.units })} />
            </div>
        );
    }
}

type EventInfoProps = WithUserAware & { eventId: string } & WithStyles<typeof styles>;

export class EventInfo extends React.Component<EventInfoProps> {
    state: { event?: Event; isNewEvent?: boolean; } = {};

    componentDidMount() {
        const { userAware, eventId } = this.props;
        const addedId = localStorage.getItem(ITEM_NEW_EVENT_ID);
        if (addedId === eventId || userAware.eventId === eventId) {
            userAware.setEventId(undefined);
            localStorage.removeItem(ITEM_NEW_EVENT_ID);
            setTimeout(() => this.setState({ isNewEvent: true }), 100);
        } else {
            /// setTimeout(() => this.setState({ isNewEvent: true }), 1000); // DEBUG: HIDE or REMOVE!!!
        }
    }

    private setEvent = (event: Event) => {
        event.handicapSystem = correctHandicapSystem(event.handicapSystem);
        this.setState({ event });
    }

    renderContent() {
        const { userAware, classes } = this.props;
        const { event } = this.state;
        if (!event) {
            return null;
        }
        if (!event.exists) {
            const err = 'Event not found: ' + event.id;
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        if (!userAware.user) {
            const err = 'Incorrect user context!';
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        if (event.userId !== userAware.workingUserId || (event.deleted && !userAware.effectiveUserId)) {
            return null;
        }
        return <RenderInfoTab event={event} classes={classes} />;
    }

    render() {
        const { eventId } = this.props;
        const { event, isNewEvent } = this.state;
        return <>
            {this.renderContent()}
            <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} docReference={Backend.eventFields(eventId)} />
            {(event && isNewEvent) &&
                <EventCreatedDialog
                    event={event}
                    handleClose={page => this.setState({ isNewEvent: false }, () => pushUrl(`/events/${event.id}/${page}`))}
                />}
        </>;
    }
}

export default function() {
    const classes = useAppStyles();
    const userAware = useUserAware();
    const { id } = useParams<'id'>();
    return id ? <EventInfo eventId={id} userAware={userAware} classes={classes} /> : null;
}
