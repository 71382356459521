import * as React from 'react';
import { createStore, combineReducers } from 'redux';
import { ProgressDialog, progressReducer, showProgressAction, hideProgressAction, hideAllProgressAction } from './ReduxProgress';
import { Provider } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { ErrorHandler, errorReducer, showErrorAction } from './ReduxError';
import { RouteHandler, routerReducer, routeToAction } from './ReduxRouter';
import { AlertProps, AlertButton, AlertHandler1, AlertHandler2, alertReducer1, alertReducer2, showAlertAction, hideAlertAction } from './ReduxAlert';
import { InfoIcon } from '../common/Icons';
import { Flex } from 'src/common/Misc';
import { AppColors } from 'src/main/Theme';

export type ProgressFunction = (content?: React.ReactNode | undefined, showAsAlert?: boolean) => void;

export const store = createStore(combineReducers({
    progressReducer, errorReducer, routerReducer, alertReducer1, alertReducer2
}));

export type InfoElementProps = {
    alertMsg?: string;
    disabled?: boolean;
    rightIcon?: boolean;
    iconColor?: string;
    onCLick?: () => void;
    icon?: React.ReactNode;
    children: React.ReactNode;
    iconStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
};

export const InfoElement = (props: InfoElementProps) => {
    const { children, alertMsg, rightIcon, disabled, iconColor, style, contentStyle, iconStyle, icon, onCLick } = props;
    const buttonStyle = {
        margin: 0,
    } as React.CSSProperties;
    const iconElement = icon ?? <InfoIcon htmlColor={iconColor} style={iconStyle} />;
    const iconButton = onCLick || alertMsg ? (
        <IconButton
            disabled={disabled}
            style={buttonStyle}
            onClick={() => onCLick ? onCLick() : showAlert(alertMsg)}>
            {iconElement}
        </IconButton>
    ) : iconElement;
    const paddingStyle = {
        paddingRight: 8
    } as React.CSSProperties;
    return rightIcon ? (
        <Flex maxWidth="fit-content" style={style}>
            <span style={{ paddingRight: 4, ...contentStyle }}>
                {children}
            </span>
            {iconButton}
            <span style={paddingStyle} />
        </Flex>
    ) : (
        <Flex maxWidth="fit-content" style={style}>
            {iconButton}
            <span style={{ paddingLeft: 4, ...contentStyle }}>
                {children}
            </span>
            <span style={paddingStyle} />
        </Flex>
    );
}

export type BoxedInfoElementProps = {
    iconColor?: string;
    sx?: React.CSSProperties;
    children: React.ReactNode;
};

export const BoxedInfoElement = (props: BoxedInfoElementProps) => {
    const { children, iconColor, sx } = props;
    return <Typography sx={{ padding: 1, backgroundColor: AppColors.webGrey100, borderRadius: 1, ...sx }}>
        <InfoElement
            iconColor={iconColor}
            contentStyle={{ color: AppColors.webBlack, fontSize: 12, fontWeight: 400, lineHeight: '150%' }}>
            {children}
        </InfoElement>
    </Typography>
}

export const NoticeElement = (props: { children: React.ReactNode, disabled?: boolean }) => {
    const { children, disabled } = props;
    return (
        <IconButton
            size='small'
            disabled={disabled}
            onClick={(e: React.SyntheticEvent<any>) => { e.preventDefault(); e.stopPropagation(); showAlert(children) }}>
            <InfoIcon color={disabled ? 'inherit' : 'secondary'} />
        </IconButton>
    );
}

export function ReduxRoot(props: { children?: React.ReactNode }) {
    return (
        <Provider store={store}>
            <>
                {props.children}
                <ProgressDialog />
                <ErrorHandler />
                <RouteHandler />
                <AlertHandler1 />
                <AlertHandler2 />
            </>
        </Provider>
    );
}

let alertId = 0;
let alerts = 0;

export function showProgress(src: string): ProgressFunction {
    alerts++;
    const token = alertId++; // Math.random();
    // console.log(`showAlert ${token} - ${alerts} - ${src}`);
    store.dispatch(showProgressAction(token, src));
    return (content?: React.ReactNode, alert?: boolean) => {
        alerts--;
        // console.log(`hideAlert ${token} -- ${alerts}`);
        store.dispatch(hideProgressAction(token));
        if (content) {
            if (alert) {
                showAlert(content);
            } else {
                showError(content);
            }
        }
    };
}

export function showError(content: React.ReactNode) {
    store.dispatch(showErrorAction(content));
}

export function pushUrl(url: string) {
    store.dispatch(routeToAction(url, false));
}

export function replaceUrl(url: string) {
    store.dispatch(routeToAction(url, true));
}

export function showAlert(content: React.ReactNode, buttons?: AlertButton[]) {
    store.dispatch(showAlertAction({ content, buttons }));
}

export function showAlert2(content: React.ReactNode, buttons?: AlertButton[]) {
    store.dispatch(showAlertAction({ content, buttons, second: true }));
}

export function showAlertProps(props: AlertProps) {
    store.dispatch(showAlertAction(props));
}

export function hideAlert(props?: AlertProps) {
    store.dispatch(hideAlertAction(props));
}

export function hideAllProgresses() {
    store.dispatch(hideAllProgressAction());
}
