import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';
import { Badge, List, ListItem, Menu, MenuItem } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { useDrag, useDrop } from 'react-dnd';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FixedWidthChip, FixedWidthChipLong, FixedWidthChipLonger, FixedWidthChipLongest } from '../../../../common/components/FixedWidthChip';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import { ButtonBadge, NoWrapOverflow, Spacing } from '../../../../common/Misc';
import { WizardIcon } from '../../../../common/Icons';
import {
    EventBase, EventData, Score, Distance, ReportedScore, Contact, ContactDetails, Team, GolferGroup, TeeTimeSettings, HandicapMode, GenderMode,
    golfersOrTeamsPerGroup, getGroupFullness, teeTimeName, golfersOrTeams, getHolesRange, formatTeeTime
} from '../../../../types/EventTypes';
import { fullName, golferTeamName, golfersOfTeam, shortName, getSameNameGolfersIds } from '../../../../contact/Contact';
import { addGolferOrTeamToGroup, elog, deleteGolfersFromEvent, saveContact, deleteInviteCode, scheduleGroupsAndSave, getUnscheduledCount } from '../../../Event';
import * as Backend from '../../../../util/firebase';
import { teeSettingsName, getTeeTime } from '../../../TeeTimes';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import { EditTeeTimeDialog } from '../../settings/format/EditTeeTimeField';
import EditGroupsDialog from './EditGroupsDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import * as Utils from '../../../../util/utility';
import { styles, useAppStyles } from '../../../../styles';
import { InfoElement, showAlert, showProgress } from '../../../../redux/ReduxConfig';
import { withProgress } from 'src/util/ProgressPromise';
import { AppColors } from 'src/main/Theme';
import { printCartSigns, printSchedule, printScorecards } from '../../tools/PrintTab';
import { EmailVariant, sendPaymentMessage } from "../../../../util/email_utils";
import { WithUserAware, useUserAware } from 'src/auth/Auth';
import ProPlanDialog from 'src/auth/ProPlanDialog';
import ProBadge from 'src/common/ProBadge';

const DRAG_TYPE_GROUP = 'GROUP';

function showAdjustmentsAlert(reportedContactNames: Array<string>) {
    showAlert(`${reportedContactNames.join(', ')} can no longer be rescheduled, as they have already started scoring for this event. Schedule adjustments can be made prior to golfers starting the round and adding scores.`, [
        { title: 'Ok', color: 'secondary' }
    ]);
}

function reportedContacts(teamOrGolferId: string, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    let reportedContactsName: Array<string> = [];
    const golfer = golfers.get(teamOrGolferId);
    if (golfer && golfer.reportedBy) {
        reportedContactsName = [fullName(golfer)];
    } else {
        const team = teams.get(teamOrGolferId);
        if (team) {
            reportedContactsName = golfersOfTeam(team, golfers).filter(g => g.reportedBy).map(g => fullName(g));
        }
    }
    return reportedContactsName;
}

export interface GroupSummary {
    group: GolferGroup;
    teams: Array<Team>;
    golfers: Array<Contact>;
    groupFullness: number;
}

interface GroupRowItem {
    golferOrTeamId: string
    names: Array<string>
    homeCourseOrCities?: Array<string>;
    hidden: boolean;
    isPlaceholder: boolean;
    isReported: boolean;
    version: number;
}

interface GroupItemProps {
    golferOrTeamId: string;
    names: Array<string>;
    homeCourseOrCities?: Array<string>;
    isPlaceholder: boolean;
    isGroupOver: boolean;
    isReported: boolean;
    position: number;
    groupSummary: GroupSummary;
    allTeams: Map<string, Team>;
    allGolfers: Map<string, Contact>;
    dropToGroup: (golferOrTeamId: string, groupId: string, position?: number) => void;
    onDelete: () => void;
    onOpen: (id: string) => void;
}

interface GroupRowProps {
    initialItems: Array<GroupRowItem>;
    eventOrRound: EventBase;
    groupSummary: GroupSummary;
    allTeams: Map<string, Team>;
    allGolfers: Map<string, Contact>;
    addToGroup: (groupSummary: GroupSummary) => void;
    dropToGroup: (teamOrGolferId: string, groupId: string, position?: number) => void;
    openGolfer: (id: string) => void;
    deleteFromGroup: (groupSummary: GroupSummary, teamOrGolferId: string) => void;
}

function gatherItems(event: EventBase, groupSummary: GroupSummary, golfers: Map<string, Contact>, version: number): Array<GroupRowItem> {
    const items: Array<GroupRowItem> = [];
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    if (event.teamSize > 1) {
        groupSummary.teams.forEach(team => {
            const teamMembers = golfersOfTeam(team, golfers);
            const coursesOrCities = teamMembers.filter(contact => sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity).map(contact => contact.homeCourseOrCity!);
            items.push({
                golferOrTeamId: team.id,
                names: teamMembers.map(golfer => shortName(golfer, undefined, 10)),
                homeCourseOrCities: coursesOrCities.length === 0 ? undefined : coursesOrCities,
                isPlaceholder: false,
                hidden: false,
                isReported: !!teamMembers.find(g => !!g.reportedBy),
                version
            })
        })
    } else {
        groupSummary.golfers.forEach(contact => items.push({
            golferOrTeamId: contact.id,
            names: [fullName(contact)],
            homeCourseOrCities: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? [contact.homeCourseOrCity] : undefined,
            isPlaceholder: false,
            hidden: !golfers.has(contact.id),
            isReported: !!contact.reportedBy,
            version
        }));
    }
    return items;
}

function GroupItem(thisItem: GroupItemProps) {
    const classes = useAppStyles();
    const { dropToGroup } = thisItem;
    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: DRAG_TYPE_GROUP,
        item: thisItem,
        collect: monitor => ({ isDragging: monitor.isDragging() })
    }), [thisItem]);
    const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
        accept: DRAG_TYPE_GROUP,
        canDrop: (draggingItem: GroupItemProps) => {
            return !thisItem.isPlaceholder && (thisItem.groupSummary.groupFullness < 1 ||
                !!thisItem.groupSummary.golfers.find(g => g.id === draggingItem.golferOrTeamId) ||
                !!thisItem.groupSummary.teams.find(t => t.id === draggingItem.golferOrTeamId));
        },
        drop: (draggingItem: GroupItemProps) => {
            const reportedContactNames = reportedContacts(draggingItem.golferOrTeamId, thisItem.allGolfers, thisItem.allTeams);
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                dropToGroup(draggingItem.golferOrTeamId, thisItem.groupSummary.group.id, thisItem.position);
            }
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            draggedItem: monitor.getItem() as GroupItemProps
        }),
    }), [thisItem]);
    const label = (
        <NoWrapOverflow className={classes.chipPaper}>
            {thisItem.names.map((name, idx) => <React.Fragment key={idx}>
                <PersonIcon className={classes.textIcon} />{name}
                <span className={classes.homeCourseOrCity}>
                    {thisItem.homeCourseOrCities && thisItem.homeCourseOrCities[idx] ? ` (${thisItem.homeCourseOrCities[idx]})` : ''}
                </span>
            </React.Fragment>)}
        </NoWrapOverflow>
    );
    const chipProps = {
        label,
        onDelete: thisItem.onDelete,
        color: isOver && canDrop ? 'secondary' : 'default',
        style: { backgroundColor: thisItem.isPlaceholder ? AppColors.webGreen200 : isDragging ? AppColors.webGreen100 : undefined },
        onDoubleClick: () => thisItem.onOpen(thisItem.golferOrTeamId)
    } as any;
    return (
        <span ref={dragRef}>
            <span ref={dropRef}>
                <span style={{ height: '100%', borderRadius: 16 }}>
                    {thisItem.names.length === 1 && <FixedWidthChip {...chipProps} />}
                    {thisItem.names.length === 2 && <FixedWidthChipLong {...chipProps} />}
                    {thisItem.names.length === 3 && <FixedWidthChipLonger {...chipProps} />}
                    {thisItem.names.length === 4 && <FixedWidthChipLongest {...chipProps} />}
                </span>
            </span>
        </span>
    );
};

export interface GroupRowState {
    items: Array<GroupRowItem>;
}

function GroupRow(props: GroupRowProps) {
    const { initialItems, eventOrRound, groupSummary, addToGroup, openGolfer, deleteFromGroup, allGolfers, allTeams, dropToGroup } = props;
    const [{ isOver, canDrop, draggingItem }, dropRef] = useDrop(() => ({
        accept: DRAG_TYPE_GROUP,
        canDrop: () => groupSummary.groupFullness < 1,
        drop: (dragItem: GroupItemProps, monitor) => {
            if (!monitor.didDrop()) {
                const reportedContactNames = reportedContacts(dragItem.golferOrTeamId, allGolfers, allTeams);
                if (reportedContactNames.length > 0) {
                    showAdjustmentsAlert(reportedContactNames);
                } else {
                    dropToGroup(dragItem.golferOrTeamId, groupSummary.group.id);
                }
            }
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            draggingItem: monitor.getItem() as GroupItemProps
        }),
    }), [props]);
    const classes = useAppStyles();
    const showIcon = groupSummary.groupFullness < 1 && !draggingItem;
    const addHoverItem = draggingItem && canDrop && isOver && draggingItem.groupSummary.group.id !== groupSummary.group.id;
    const itemsToDisplay = initialItems.filter(i => !i.hidden);
    if (addHoverItem) {
        itemsToDisplay.splice(itemsToDisplay.length, 0, { ...draggingItem, hidden: false, version: -1, isPlaceholder: true });
    }
    const contactsList = itemsToDisplay.map((item, idx) =>
        <GroupItem
            position={idx}
            golferOrTeamId={item.golferOrTeamId}
            key={item.golferOrTeamId}
            names={item.names}
            isGroupOver={isOver}
            isReported={item.isReported}
            isPlaceholder={item.isPlaceholder}
            homeCourseOrCities={item.homeCourseOrCities}
            {...{ groupSummary, allGolfers, allTeams }}
            onOpen={id => openGolfer(id)}
            onDelete={() => deleteFromGroup(groupSummary, item.golferOrTeamId)}
            dropToGroup={dropToGroup}
        />);
    const holesRange = getHolesRange(eventOrRound.holesType);
    const teeTime = teeTimeName(getTeeTime(eventOrRound.teeTime, holesRange, groupSummary.group.order));
    return (
        <div ref={dropRef}>
            <ListItem className={classes.listItem} style={{ backgroundColor: isOver && canDrop ? AppColors.webBlue100 : undefined }}>
                <Grid container wrap="nowrap">
                    <Grid item style={{ height: 52 }} className={classes.centeredGrid + ' ' + (eventOrRound.teeTime.mode === 'regular' ? classes.labelTeeRegular : classes.labelTeeShotgun)}>
                        <Typography variant="body2" noWrap color={'initial'} fontFamily="roboto">
                            {teeTime}
                        </Typography>
                        {groupSummary.groupFullness >= 1 &&
                            <span style={{ color: 'red', fontSize: '0.65rem' }}>
                                {Utils.withS(contactsList.length, eventOrRound.teamSize === 1 ? 'golfer' : 'team')}
                            </span>}
                    </Grid>
                    <Grid item className={classes.centeredGrid}>
                        {contactsList}
                        {showIcon &&
                            <IconButton
                                size="large"
                                className={classes.smallIconButton}
                                onClick={() => addToGroup(groupSummary)}>
                                <AddCircleOutlineIcon />
                            </IconButton>}
                    </Grid>
                </Grid>
            </ListItem >
            <Divider />
        </div>
    );
};

interface State {
    doSchedule: boolean;
    editingTeeTimes: boolean;
    editingGroup: number;
    editedContact?: ContactDetails;
    handleExport: boolean;
    printMenuOpen: boolean;
    count: number;
    proBanner?: boolean;
}

type Props = { eventData: EventData; } & WithStyles<typeof styles> & WithUserAware;

class ScheduleList extends React.Component<Props, State> {

    private readonly printMenuRef: React.RefObject<HTMLButtonElement> = React.createRef();

    state: State = {
        doSchedule: false,
        editingTeeTimes: false,
        editingGroup: -1,
        handleExport: false,
        printMenuOpen: false,
        count: 0
    };

    componentDidMount() {
        Backend.trackEvent('view_schedule');
    }

    private handleExport = () => this.setState({ handleExport: true });
    private handleTeeTimeSettingsClose = () => this.setState({ editingTeeTimes: false });
    private addToGroup = (groupSummary: GroupSummary) => this.setState({ editingGroup: groupSummary.group.order });

    private eventOrRound() {
        const { event, selectedRound } = this.props.eventData;
        return selectedRound ?? event;
    }

    private getStaff() {
        const { event, golfersMap, teamsMap, groupsMap, teeTimesMap, competitionsMap } = this.props.eventData;
        const eventOrRound = this.eventOrRound();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teeTimes = teeTimesMap.get(eventOrRound.id) ?? [];
        const competitions = competitionsMap.get(this.eventOrRound().id) ?? [];
        return { event, eventOrRound, golfers, teams, groups, competitions, teeTimes };
    }

    private handleReset = () => {
        const { eventOrRound, golfers, groups } = this.getStaff();
        let reportedGolfers: Array<Contact> = []
        groups.forEach(g => g.contactIds.forEach(id => {
            if (golfers.get(id)?.reportedBy) {
                reportedGolfers.push(golfers.get(id)!);
            }
        }));
        if (reportedGolfers.length === 0) {
            withProgress(Backend.removeBatch(Backend.golferGroupDb(eventOrRound.id), groups, deleteInviteCode)
                .then(() => elog(eventOrRound, `Groups reset `, `${groups.length} removed`, '')));
        } else {
            const reportedContactNames = reportedGolfers.map(golfer => fullName(golfer));
            showAdjustmentsAlert(reportedContactNames);
        }
    }

    private saveTeeTime = (val: TeeTimeSettings, eventTime?: number) => {
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const groupCount = golfersOrTeamsPerGroup(eventOrRound, val.golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            let reportedContactNames: Array<string> = [];
            updatedGroups.forEach(g => g.contactIds.forEach(id => reportedContactNames = reportedContactNames.concat(reportedContacts(id, golfers, teams))))
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                showAlert("Reducing the group size will automatically remove some golfers from the existing groups. ", [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => this.saveTeeTimeConfirmed(val, eventTime) }
                ]);
            }
        } else {
            this.saveTeeTimeConfirmed(val, eventTime);
        }
    }

    private saveTeeTimeConfirmed = async (val: TeeTimeSettings, eventTime?: number) => {
        const eventOrRound = this.eventOrRound();
        this.setState({ editingTeeTimes: false });
        const eventChange = {
            id: eventOrRound.id,
            exists: true,
            teeTime: val,
            date: eventTime ?? eventOrRound.date
        };
        const hideProgress = showProgress('saveTeeTimeConfirmed');
        try {
            await Backend.updateOrAdd(Backend.eventsDb, eventChange);
            await this.adjustGroupsSize(val.golfersPerGroup);
            this.generateTeeTimes(eventOrRound);
            elog(eventOrRound, 'Tee times changed',
                `startTime: ${formatTeeTime(val)} mode: ${val.mode} groups size: ${val.golfersPerGroup} starting type: ${val.startingHolesType}`,
                `Id: ${eventOrRound.id}`)
        } finally {
            hideProgress();
        }
    }

    private adjustGroupsSize = async (golfersPerGroup: number) => {
        const { eventOrRound, groups } = this.getStaff();
        const groupCount = golfersOrTeamsPerGroup(eventOrRound, golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            const usedOrders = new Set();
            groups.filter(g => g.contactIds.length > 0).forEach(g => usedOrders.add(g.order));
            updatedGroups.forEach(g => g.contactIds.splice(groupCount, g.contactIds.length - groupCount));
            await Backend.updateOrAddBatch(Backend.golferGroupDb(eventOrRound.id), updatedGroups);
        }
    }

    private deleteFromGroup = (groupSummary: GroupSummary, teamOrGolferId: string) => {
        const { eventOrRound, golfers, teams } = this.getStaff();
        const reportedContactNames = reportedContacts(teamOrGolferId, golfers, teams);
        if (reportedContactNames.length > 0) {
            showAdjustmentsAlert(reportedContactNames);
        } else {
            const group = groupSummary.group;
            const idx = group.contactIds.indexOf(teamOrGolferId);
            group.contactIds.splice(idx, 1);
            Backend.updateOrAdd(Backend.golferGroupDb(eventOrRound.id), group);
        }
    }

    private generateTeeTimes(eventOrRound: EventBase) {
        const { genTeeTimes } = this.props.eventData;
        genTeeTimes(eventOrRound.id);
    }

    private groupSummary(group: GolferGroup, eventOrRound: EventBase): GroupSummary {
        const { golfersMap, teamsMap } = this.props.eventData;
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const groupGolfers: Array<Contact> = [];
        const groupTeams: Array<Team> = [];
        if (eventOrRound.teamSize === 1) {
            group.contactIds.forEach(id => {
                const golfer = golfers.get(id);
                if (golfer) {
                    groupGolfers.push(golfer);
                } else {
                    groupGolfers.push({ id, lastName: 'id:' + id.substring(0, 6) + '..', gender: 'male', hidden: false });
                }
            });
        } else {
            group.contactIds.forEach(id => {
                const team = teams.get(id);
                if (team) {
                    groupTeams.push(team);
                } else {
                    groupTeams.push({ id, order: -1, contactIds: [] });
                }
            });
        }
        return {
            group,
            teams: groupTeams,
            golfers: groupGolfers,
            groupFullness: getGroupFullness(eventOrRound, group)
        };
    }

    private scheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) => {
        const { eventOrRound } = this.getStaff();
        const { eventData } = this.props;
        withProgress(scheduleGroupsAndSave(eventOrRound, eventData, genderMode, handicapMode, keepGroups));
    }

    private handleScheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) =>
        this.setState({ doSchedule: false }, () => this.scheduleGroups(genderMode, handicapMode, keepGroups));

    private openGolfer = (id: string) => this.setState({ editedContact: this.props.eventData.golfersAggregated.get(id) });

    private printScorecards = async () => {
        const { userAware } = this.props;
        if (userAware.hasPro) {
            const { event, golfers, teams, groups, competitions } = this.getStaff();
            this.setState({ printMenuOpen: false });
            withProgress(printScorecards(event, this.eventOrRound(), golfers, teams, groups, competitions));
        } else {
            this.setState({ proBanner: true, printMenuOpen: false });
        }
    }

    private printSchedule = () => {
        const { userAware } = this.props;
        if (userAware.hasPro) {
            const { groups, golfers, teams } = this.getStaff();
            this.setState({ printMenuOpen: false });
            withProgress(printSchedule(this.eventOrRound(), golfers, teams, groups));
        } else {
            this.setState({ proBanner: true, printMenuOpen: false });
        }
    }

    private printCartsigns = () => {
        const { userAware } = this.props;
        if (userAware.hasPro) {
            const { groups, golfers, teams } = this.getStaff();
            this.setState({ printMenuOpen: false });
            withProgress(printCartSigns(this.eventOrRound(), golfers, teams, groups));
        } else {
            this.setState({ proBanner: true, printMenuOpen: false });
        }
    }

    private handleContactDeleted = (golfers: Array<Contact>) => {
        const { event } = this.props.eventData;
        withProgress(deleteGolfersFromEvent(event, golfers, true));
    }

    private handleContactChanged = (contactDetails: ContactDetails) => {
        const { event } = this.props.eventData;
        saveContact(event, contactDetails, 'Golfer modified')
            .then(() => this.setState({ editedContact: undefined }));
    }

    private exportData = () => {
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const groupSize = Math.max(Math.floor(((eventOrRound.teeTime && eventOrRound.teeTime.golfersPerGroup) ? eventOrRound.teeTime.golfersPerGroup : 4) / eventOrRound.teamSize), 1);
        const exportData: string[][] = [];
        const exportHeader = ['Start'];
        if (exportData) {
            for (let i = 1; i <= groupSize; i += 1) {
                if (eventOrRound.teamSize === 1) {
                    exportHeader.push('Golfer ' + i);
                } else {
                    exportHeader.push('Team ' + i);
                }
            }
            exportData.push(exportHeader);
            groups.forEach(group => {
                if (group.contactIds.length > 0) {
                    let exportRow: string[] = [];
                    const holesRange = getHolesRange(eventOrRound.holesType);
                    const groupTime = teeTimeName(getTeeTime(eventOrRound.teeTime, holesRange, group.order));
                    exportRow.push(groupTime);
                    group.contactIds.forEach(contactId => {
                        if (eventOrRound.teamSize === 1) {
                            const contact = golfers.get(contactId);
                            if (contact) {
                                exportRow.push(fullName(contact));
                            }
                        } else {
                            const team = teams.get(contactId);
                            if (team) {
                                exportRow.push(golferTeamName(team, golfers));
                            }
                        }
                    });
                    exportData.push(exportRow);
                }
            });
        }
        return exportData;
    }

    private unscheduledGolfersOrTeams = (eventOrRound: EventBase) => {
        const { golfersMap, teamsMap, groupsMap } = this.props.eventData;
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        return teamsMap.size > 0 && golfersMap.size > 0 && groupsMap.size > 0 ? getUnscheduledCount(groups, teams, golfers, eventOrRound.teamSize) : 0;
    }

    private Groups = () => {
        const { classes } = this.props;
        const { event, rounds, selectedRound, setSelectedRound, groupsMap, teamsMap, golfersMap } = this.props.eventData;
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const { handleExport, printMenuOpen, count } = this.state;
        const moveToGroup = (teamOrGolferId: string, groupId: string, position?: number) => {
            withProgress(addGolferOrTeamToGroup(teamOrGolferId, groupId, groups, eventOrRound, position))
                .then(() => this.setState({ count: count + 1 }));
        }
        const groupSize = Math.max(Math.floor((eventOrRound.teeTime?.golfersPerGroup || 4) / eventOrRound.teamSize), 1);
        const teeTimeInfo = <>
            <Typography>{teeSettingsName(eventOrRound)}</Typography>
            <Typography>{groupSize === 1 ? (eventOrRound.teamSize > 1 ? '1 team per tee time' : '1 person per tee time') :
                groupSize + (eventOrRound.teamSize > 1 ? ' teams per tee time' : ' people per tee time')}
            </Typography>
        </>;
        let unscheduledInfo: React.ReactNode = '';
        const scheduled = new Set<string>();
        if (groupsMap.size > 0) {
            groups.forEach(group => group.contactIds.forEach(contactId => scheduled.add(contactId)));
            const unscheduled = getUnscheduledCount(groups, teams, golfers, eventOrRound.teamSize);
            if (scheduled.size === 0) {
                unscheduledInfo = <InfoElement iconColor={AppColors.webWarning}>
                    The round will start soon, set the schedule for the participants.
                </InfoElement>;
            } else if (unscheduled > 0) {
                unscheduledInfo = <Typography variant="body1" style={{ marginLeft: 16 }}>
                    {Utils.withS(unscheduled, eventOrRound.teamSize === 1 ? 'golfer' : 'team') + ' not scheduled yet'}
                    <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                </Typography>;
            }
        }
        const fileName = event.name.replace(' ', '-') + (selectedRound ? `-round${selectedRound.roundOrder}` : '') + '-' + Utils.formatDateDashed2(eventOrRound.date);
        const exportFile = `${fileName}-schedule.csv`;
        const exportData = handleExport ? this.exportData() : '';
        const selectedRoundNameInMenu = selectedRound ? `(Round ${selectedRound.roundOrder})` : '';
        const version = groupsMap.size * 1000000 + teamsMap.size * 10000 + golfersMap.size + 1000 * (selectedRound?.roundOrder ?? 0) + count;
        /*
            DEBUG INFORMATION<br/>
            GROUPS-MAP<BR/>
            {Array.from(groupsMap.entries()).map((e, idx) => {
                return <pre key={idx}>prep {e[0]} - {e[1].map(gg => `${gg.id}-${gg.contactIds.length}[${gg.contactIds.join()}]`).join()}</pre>;
            })}
            LOADED-GROUPS-MAP<BR/>
            {Array.from(loadedGroupsMap.entries()).map((e, idx) => {
                return <pre key={idx}>orig {e[0]} - {e[1].map(gg => `${gg.id}-${gg.contactIds.length}[${gg.contactIds.join()}]`).join()}</pre>;
            })}
            GROUPS<br />
            {groups.map((group, idx) => {
                const groupSummary = this.groupSummary(group, eventOrRound);
                return <pre key={idx}>{group.order} - {group.id} - {version} - [{groupSummary.golfers.map(g => fullName(g)).join('] [')}]</pre>
            })}
        */
        return (
            <div className={classes.listRootGrey}>
                {event.type === 'multiday' && <>
                    <ButtonBar>
                        {rounds.map(round => <AppButton
                            key={round.roundOrder}
                            className={classes.eventRoundButton}
                            color={round.roundOrder === selectedRound?.roundOrder ? "primary" : "info"}
                            sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                            onClick={() => setSelectedRound(round)} >
                            Round {round.roundOrder}
                            <ButtonBadge
                                invisible={this.unscheduledGolfersOrTeams(round) === 0}
                                selected={round.roundOrder === selectedRound?.roundOrder} />
                        </AppButton>)}
                    </ButtonBar>
                </>}
                <Spacing height={8} />
                <List disablePadding>
                    <LabeledField label={'Tee times'} itemClass={classes.listItem} value={teeTimeInfo} edit={() => this.setState({ editingTeeTimes: true })} />
                </List>
                <Spacing />
                <List disablePadding>
                    <ButtonBar style={{ backgroundColor: AppColors.white, margin: 0, padding: '16px', paddingTop: '4px' }}>
                        <AppButton color="secondary" onClick={() => this.setState({ doSchedule: true })}>
                            <WizardIcon className={classes.leftButtonIcon} />Schedule...
                        </AppButton>
                        {scheduled.size > 0 &&
                            <CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }} >
                                <AppButton color="info" onClick={this.handleExport}>Export</AppButton>
                            </CSVLink>}
                        {scheduled.size > 0 &&
                            <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>}
                        {unscheduledInfo}
                        <AppButton color="info"
                            ref={this.printMenuRef}
                            style={{ paddingRight: 8, marginLeft: 'auto', marginRight: 0 }}
                            onClick={() => this.setState({ printMenuOpen: !printMenuOpen })}>
                            <ProBadge small marginRight={4} />
                            Print
                            <ArrowDropDownIcon className={classes.rightButtonIcon} />
                        </AppButton>
                        <Menu
                            open={printMenuOpen}
                            anchorEl={this.printMenuRef.current}
                            onClose={() => this.setState({ printMenuOpen: false })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                            <MenuItem onClick={this.printScorecards}>Scorecards {selectedRoundNameInMenu}</MenuItem>
                            <MenuItem onClick={this.printSchedule}>Schedule {selectedRoundNameInMenu}</MenuItem>
                            <MenuItem onClick={this.printCartsigns}>Cart signs {selectedRoundNameInMenu}</MenuItem>
                        </Menu>
                    </ButtonBar>
                    {groups.map((group, idx) => {
                        const groupSummary = this.groupSummary(group, eventOrRound);
                        return <GroupRow
                            key={idx}
                            initialItems={gatherItems(eventOrRound, groupSummary, golfers, version)}
                            eventOrRound={eventOrRound}
                            groupSummary={groupSummary}
                            allTeams={teams}
                            allGolfers={golfers}
                            addToGroup={this.addToGroup}
                            dropToGroup={moveToGroup}
                            openGolfer={this.openGolfer}
                            deleteFromGroup={this.deleteFromGroup}
                        />
                    })}
                    <Spacing height={8} />
                </List>
            </div>
        );
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { eventData } = this.props;
        await sendPaymentMessage(contactsToSend, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { eventData, userAware } = this.props;
        const { eventOrRound, golfers, teams, groups, teeTimes } = this.getStaff();
        const { doSchedule, editingTeeTimes, editedContact, editingGroup, count, proBanner } = this.state;
        const { event, rounds, teamsMap, groupsMap, golfersMap } = eventData;
        const countPerGroup = golfersOrTeamsPerGroup(eventOrRound);
        return <>
            <this.Groups />
            {!!editedContact && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                actionMode="edit"
                hasPro={!!userAware.hasPro}
                initialContact={editedContact}
                saveToEvent={this.handleContactChanged}
                sendEmail={this.handleSendMessage}
                deleteFromEvent={this.handleContactDeleted}
                handleClose={() => this.setState({ editedContact: undefined })}
            />}
            {(teeTimes.length > 0 && doSchedule) && <AutoScheduleDialog
                pairing={false}
                eventOrRound={eventOrRound}
                open={doSchedule}
                close={() => this.setState({ doSchedule: false })}
                save={this.handleScheduleGroups}
                golfersOrTeams={golfersOrTeams(eventOrRound.teamSize === 1 ? golfers : teams, groups, true)}
                groups={groups}
                count={teeTimes.length}
                groupSize={countPerGroup}
            />}
            {editingTeeTimes && <EditTeeTimeDialog
                eventOrRound={eventOrRound}
                settings={eventOrRound.teeTime}
                saveSettings={this.saveTeeTime}
                onClose={this.handleTeeTimeSettingsClose}
            />}
            {editingGroup >= 0 && <EditGroupsDialog
                eventOrRound={eventOrRound}
                editingGroup={editingGroup}
                {...{ teams, groups, golfers, loadedTeams: teamsMap.size, loadedGroups: groupsMap.size, loadedGolfers: golfersMap.size }}
                handleClose={() => this.setState({ editingGroup: -1, count: count + 1 })}
            />}
            {proBanner &&
                <ProPlanDialog
                    handleClose={() => this.setState({ proBanner: false })}
                />}
        </>;
    }
}

export default function(props: Omit<Props, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <ScheduleList classes={classes} userAware={userAware} {...props} />;
}
