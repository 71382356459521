import * as React from 'react';
import { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import { DialogContent, ImageList, ImageListItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import * as Backend from '../../../../util/firebase';
import ImageCropDialog from '../../../../common/components/ImageCropDialog';
import { showError } from '../../../../redux/ReduxConfig';
import { useAppStyles } from "../../../../styles";
import { WithUserAware, useUserAware } from 'src/auth/Auth';

const descriptions: Record<Backend.ImageType, any> = {
    badge: {
        header: 'Badges',
        name: 'badge',
        w: 300,
        h: 300,
        cols: 1
    },
    banner: {
        header: 'Banners',
        name: 'banner',
        w: 1000,
        h: 250,
        cols: 4
    }
};

const style = (theme: Theme) => {
    return {
        margin: {
            marginTop: theme.spacing(2)
        },
        imageList: {
            height: 450,
            width: '100%',
            overflow: 'unset'
        }
    } as StyleRules;
};

interface ChooseImgDialogProps {
    open: boolean;
    imageType: Backend.ImageType;
    handleClose: () => void;
    handleSelection: (url: string) => void;
}

interface Images {
    defaultImgs: Backend.ImageSrc[];
    userImgs: Backend.ImageSrc[];
}

interface State {
    file?: File;
}

type Props = ChooseImgDialogProps & WithStyles<typeof style> & Images & WithUserAware;

class ChooseImgDialog extends React.Component<Props, State> {
    state: State = {};

    private handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.files) {
            return;
        }
        if ('image/jpeg' !== ev.target.files[0].type
            && 'image/jpg' !== ev.target.files[0].type
            && 'image/png' !== ev.target.files[0].type) {
            showError('Could not read file');
            return;
        }
        this.setState({ file: ev.target.files[0] });
        ev.currentTarget.value = '';
    }

    private setImage = (url: string) => {
        const { imageType, userAware } = this.props;
        if (!userAware.workingUserId) {
            return;
        }
        const data = { url: url, type: imageType } as Backend.ImageSrc;
        Backend.updateOrAdd(Backend.userImgDb(userAware.workingUserId), data);
        this.props.handleSelection(url);
        this.handleCropDialogClose();
    }

    private handleCropDialogClose = () => {
        this.setState({ file: undefined });
    }

    render() {
        const { classes, open, defaultImgs, userImgs, imageType, handleClose, handleSelection } = this.props;
        const { file } = this.state;
        const desc = descriptions[imageType];
        const images = [...defaultImgs];
        images.push(...userImgs);
        return <>
            <XSMobileDialog open={open} onClose={handleClose}>
                <DialogAppBar label={desc.header} close={handleClose} />
                <DialogContent>
                    <Typography variant="body2">Upload a new {desc.name}</Typography>
                    <Typography variant="body1">
                        Select an image to upload from your computer.
                        Recommended resolution: {desc.w}x{desc.h}&nbsp;pixels.
                    </Typography>
                    <AppButton color="secondary">
                        <label>
                            Upload...
                            <input onChange={this.handleFile} accept="image/jpeg,image/jpg,image/png"
                                style={{ display: 'none' }} type="file" />
                        </label>
                    </AppButton>
                    <Typography variant="body2" className={classes.margin}>Use one from from the
                        library:</Typography>
                    <ImageList rowHeight={160} cols={4} className={classes.imageList}>
                        {images.map(src => (
                            <ImageListItem key={src.url} cols={desc.cols}>
                                <img src={src.url} alt="" height={150} onClick={() => handleSelection(src.url)} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </DialogContent>
            </XSMobileDialog>
            {file && <ImageCropDialog
                file={file}
                width={desc.w}
                height={desc.h}
                rounded={false}
                storageRef={Backend.portalImagesRef}
                setImage={this.setImage}
                close={this.handleCropDialogClose}
                maxWidth={desc.w > 300 ? 'sm' : 'xs'}
            />}
        </>;
    }
}

interface Params {
    imageType: Backend.ImageType;
}

export default function WPublicImages(props: ChooseImgDialogProps & Params) {
    const [loaded, setLoaded] = React.useState(false);
    const [defaultImgs, setDefaultImgs] = React.useState<Array<Backend.ImageSrc>>([]);
    const [userImgs, setUserImgs] = React.useState<Array<Backend.ImageSrc>>([]);
    const userAware = useUserAware();
    const classes = useAppStyles();
    const { imageType } = props;
    React.useEffect(() => {
        async function getImages() {
            const [defaultImgs, userImgs] = await Promise.all([
                Backend.getEntities<Backend.ImageSrc>(Backend.getDefaultImgDb(imageType)),
                Backend.getEntities<Backend.ImageSrc>(Backend.getUserImgDb(imageType, userAware.workingUserId!))
            ]);
            setDefaultImgs(defaultImgs);
            setUserImgs(userImgs);
            setLoaded(true);
        }
        getImages();
    }, []);
    if (!loaded) {
        return null;
    }
    if (!userAware.workingUserId) {
        return null;
    }
    return <ChooseImgDialog classes={classes} userAware={userAware} defaultImgs={defaultImgs} userImgs={userImgs} {...props} />;
}
