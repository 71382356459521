import * as React from 'react';
import { ChangeEvent, FocusEvent } from 'react';
import {
    Box, Checkbox, CircularProgress, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText,
    Grid, IconButton, InputAdornment, InputLabel, Typography, useMediaQuery, useTheme,
    Accordion, AccordionSummary, AccordionDetails, Hidden
} from '@mui/material';
import SelectTeesComponent from '../event/tabs/common/SelectTeesComponent';
import { Spacing } from '../common/Misc';
import { InputLabelProps } from '@mui/material/InputLabel';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Event, Tee, Contact, ContactDetails, Gender, USGAGolferInfo, PaymentInfo, Round, EventBase } from '../types/EventTypes';
import { formatHandicap, parseGender, toContact } from './Contact';
import TextField, { emailValidation, homeCourseOrCity, maxLength, required, ValidatedTextFieldProps, ValidationRule } from '../validation/ValidatedTextField';
import withForm, { ValidateProps } from '../validation/ValidatedForm';
import EditableAvatar from './avatar/EditableContactAvatar';
import { emailFormat, makeParameterizedGetRequestUrl, roundToNextDate, toSafeString } from '../util/utility';
import { processEnterKey } from '../util/react_utils';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import AppButton from '../common/components/AppButton';
import DialogAppBar from '../common/dialog/DialogAppBar';
import TypedFormRadioLabel from '../common/form/TypedFormRadioLabel';
import { styles } from '../styles';
import { Urls } from '../util/config';
import { isValidHCPIndex, parseHandicap } from '../scoring/handicap';
import { showAlert, showProgress } from '../redux/ReduxConfig';
import * as Backend from '../util/firebase';
import { DocumentData, errMsg } from '../util/firebase';
import { showGolfersDeletionAlert, showReportedGolfersDeletionAlert } from '../util/common_alerts';
import { defaultAxiosRequestConfig } from '../util/axios_utils';
import { Buffer } from 'buffer';
import { AppColors } from '../main/Theme';
import { InfoIconTooltip } from '../common/components/InfoToolTip';
import { PaidStatusSelectionComponent } from '../payments/PaidStatusSelectionComponent';
import { formatDateUniversal, isUSGolfCourse } from '../event/Event';
import { FirebaseDocComponent } from '../common/WithData';
import { EmailVariant } from '../util/email_utils';
import ProNotice from 'src/common/ProNotice';

const usgaHandicapDataLabelVertical = '/img/usgaHandicapDataAffiliateLabelVer.svg';
const usgaHandicapDataLabelHorizontal = '/img/usgaHandicapDataAffiliateLabelHor.svg';

type FieldsDescription = { [key: string]: ValidatedTextFieldProps };

interface GenderResponse {
    name: string;
    gender: string;
    probability: string;
    count: string;
}

interface DataContactBase {
    id?: string;
    firstName?: string;
    lastName: string;
    avatar?: string;
    email?: string;
    handicapIndex?: string;
    phone?: string;
    notes?: string;
    reportedBy?: string;
    homeCourseOrCity?: string;
    handicapId?: string;
}

interface DataContact extends DataContactBase {
    hidden: boolean;
    gender: Gender;
    tee?: Tee;
    roundTees: { [s: string]: Tee; };
    feePaid?: boolean;
    feePaidDate?: number;
}

function isValidOrEmptyEmail(email?: string) {
    return !email || emailFormat.test(email);
}

function toContactDetails(contactData: DataContact, paymentDocId?: string): ContactDetails {
    const contact = { ...contactData } as ContactDetails;
    if (contact.firstName) {
        contact.firstName = contact.firstName.trim();
    }
    if (contact.homeCourseOrCity) {
        contact.homeCourseOrCity = contact.homeCourseOrCity.trim();
    }
    if (paymentDocId) {
        contact.paymentId = paymentDocId;
    }
    if (contact.lastName) {
        contact.lastName = contact.lastName.trim();
    }
    if (contactData.handicapIndex) {
        contact.handicapIndex = parseHandicap(contactData.handicapIndex);
    }
    return contact;
}

function toGolfer(contactData: DataContact, eventOrRound: EventBase): Contact {
    const golfer = toContactDetails(contactData);
    golfer.tee = contactData.roundTees[eventOrRound.id];
    return golfer;
}

function toContactData(contact?: ContactDetails): DataContact {
    if (!contact) {
        return {} as DataContact;
    }
    const { handicapIndex, ...others } = contact;
    const contactData = { ...others, roundTees: {} } as DataContact;
    contactData.handicapIndex = formatHandicap(handicapIndex);
    contactData.roundTees = Object.assign({}, contact.roundTees);
    return contactData;
}

type HandicapOption = 'Enter Manually' | 'USGA';

interface EditContactDialogProps {
    open: boolean;
    hasPro: boolean;
    event: Event;
    rounds: Array<Round>;
    initialContact?: ContactDetails;
    contactRef?: string;
    handleClose: () => void;
    saveToEvent: (contactDetails: ContactDetails, notificationLess: boolean, initialContact?: ContactDetails) => void;
    deleteFromEvent?: (contacts: Array<Contact>) => void;
    linkedFromApp?: boolean;
    onRevokeSuccess?: () => void;
    actionMode: 'register' | 'add' | 'edit' | 'confirm';
    tees?: Tee[];
    sendEmail?: (contactsToSend: Contact[], variant: EmailVariant) => Promise<void>;
}

type Props = EditContactDialogProps & WithStyles<typeof styles> & ValidateProps;

interface State {
    contactData: DataContact;
    genderBlurred: boolean;
    initialContact?: Contact;
    handicapOption: HandicapOption;
    handicapIdStatus: 'unset' | 'fromMemory' | 'failed' | 'success';
    linkedFromApp: boolean;
    loadingIdFromUSGA?: boolean;
    lastSearchedHandicapId?: string;
    lastFoundHandicapIndexUSGA?: string;
    notifyAfterAdding: boolean;
    paymentInfo?: PaymentInfo;
}

const toHandicapIndexStr = (handicapIndex: number) => {
    return handicapIndex < 0 ? `+${Math.abs(handicapIndex)}` : handicapIndex.toString();
};

class ContactDialog extends React.Component<Props, State> {

    private mounted: boolean = false;
    private controller = new AbortController();
    private genderSpecified: boolean;

    constructor(props: Props) {
        super(props);
        const { initialContact, linkedFromApp, event, actionMode } = props;
        const handicapModeUSGA = initialContact?.handicapId && initialContact.handicapIndex != null;
        this.state = {
            contactData: toContactData(initialContact),
            genderBlurred: actionMode === 'confirm' && (initialContact?.firstName?.length ?? 0) > 0,
            handicapOption: actionMode != 'confirm' && actionMode != 'register' && handicapModeUSGA && this.showUSGAUI() ? 'USGA' : 'Enter Manually',
            linkedFromApp: linkedFromApp ?? false,
            notifyAfterAdding: true,
            lastFoundHandicapIndexUSGA: handicapModeUSGA ? toHandicapIndexStr(initialContact!.handicapIndex!) : undefined,
            handicapIdStatus: handicapModeUSGA ? 'fromMemory' : 'unset',
            lastSearchedHandicapId: initialContact?.handicapId
        };
        this.genderSpecified = false;
        if (initialContact?.id && linkedFromApp === undefined) {
            Backend.getDocument(Backend.acceptedInvitesCollection(event.userId), initialContact.id)
                .then(acceptedInviteSnapshot => this.setState({ linkedFromApp: acceptedInviteSnapshot.exists() }));
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.initialContact !== state.initialContact) {
            return {
                initialContact: props.initialContact,
                data: toContactData(props.initialContact),
                openMore: false
            };
        }
        return null;
    };

    private showUSGAUI = () => {
        const { actionMode } = this.props;
        return actionMode != 'confirm' && actionMode != 'register';
    }

    private setFeePaidStatus = (feePaid: boolean) => {
        const { contactData } = this.state;
        contactData.feePaid = feePaid;
        contactData.feePaidDate = Date.now();
        this.setState({ contactData });
        return Promise.resolve();
    };

    private handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const contactData = { ...this.state.contactData };
        const trimValue = e.target.name === 'email';
        const val = toSafeString(e.target.value, trimValue);
        if (this.props.validateField) {
            this.props.validateField(e.target.name, val)
        }
        contactData[e.target.name as keyof DataContactBase] = val;
        this.setState({ contactData });
    };

    private handleSubmitButton = () => {
        this.setState({ genderBlurred: true });
        const { initialContact, saveToEvent, validate, valid, actionMode } = this.props;
        const { contactData, handicapIdStatus, lastSearchedHandicapId, lastFoundHandicapIndexUSGA, handicapOption, notifyAfterAdding } = this.state;
        if (validate) {
            validate();
        }
        if ((valid && !valid()) || !contactData.gender) {
            return;
        }
        if ((handicapIdStatus === 'success' || handicapIdStatus === 'fromMemory') && handicapOption === 'USGA') {
            contactData.handicapId = lastSearchedHandicapId;
            contactData.handicapIndex = lastFoundHandicapIndexUSGA;
        } else {
            delete contactData.handicapId;
        }
        const notificationLess = actionMode === 'add' && !notifyAfterAdding;
        saveToEvent(toContactDetails(contactData), notificationLess, initialContact);
    };

    private handleRemove = () => {
        const { contactData } = this.state;
        if (contactData.reportedBy) {
            showReportedGolfersDeletionAlert([toContactDetails(contactData)], this.handleConfirmedRemove);
        } else {
            showGolfersDeletionAlert(1, this.handleConfirmedRemove);
        }
    };

    private handleAvatarChange = (url?: string) => {
        const contactData = { ...this.state.contactData };
        contactData.avatar = url;
        this.setState({ contactData });
    };

    private handleConfirmedRemove = () => {
        const { initialContact, deleteFromEvent } = this.props;
        if (initialContact && initialContact.id && deleteFromEvent) {
            const contactDetails = toContactDetails(this.state.contactData);
            deleteFromEvent([toContact(contactDetails)]);
            this.props.handleClose();
        }
    };

    private setGender = (val: Gender) => {
        let contactData = { ...this.state.contactData };
        contactData.gender = val;
        this.setState({ contactData });
    };

    private handleGenderChange = (val: Gender) => {
        this.setGender(val);
        this.genderSpecified = true;
    };

    private handleNameBlur = (_e: FocusEvent<HTMLInputElement>) => {
        const { contactData } = this.state;
        const contactDetails = toContactDetails(contactData);
        if ((!contactDetails.id || this.props.actionMode === 'confirm')
            && contactDetails.firstName && !contactDetails.gender && !this.genderSpecified) {
            const encodedFirstName = Buffer.from(contactData.firstName || '').toString('utf8');
            axios.get(Urls.genderizeAPI, { signal: this.controller.signal, params: { name: encodedFirstName } })
                .then(({ data }: { data: GenderResponse }) => {
                    if (this.mounted && !contactDetails.gender && !this.genderSpecified) {
                        if (+data.probability > .7) {
                            this.setGender(parseGender(data.gender));
                        } else {
                            this.setGender(parseGender(''));
                        }
                    }
                }).catch(_err => {
                    if (!this.controller.signal.aborted) {
                        Backend.trackEvent('gender_determination_failed', { name: contactData.firstName });
                    }
                });
        }
    };

    private handleRevokeAction = () => {
        const { initialContact, event, onRevokeSuccess } = this.props;
        const hideProgress = showProgress('handleRevokeAction');
        Backend.deleteDocument(Backend.acceptedInvitesCollection(event.userId), initialContact!.id).then(() => {
            hideProgress('Connection revoked successfully');
            if (onRevokeSuccess) {
                onRevokeSuccess();
            }
            this.setState({ linkedFromApp: false });
        }).catch((err: any) => hideProgress(errMsg(err)));
    };

    private handleRevoke = () => {
        const { initialContact, event, classes } = this.props;
        if (initialContact?.id && event.userId) {
            const revocationRestricted = !!initialContact.reportedBy && Date.now() < roundToNextDate(event.dateUTC);
            showAlert(revocationRestricted ?
                'Golfers connection cannot be revoked during an active Event round.'
                : <>
                    Revoking this connection will remove your events from the golfers Golf Pad app.<br />
                    This connection can be reestablished with an Events invitation.<br />
                    <a href={Urls.emailInvitationsSupportLink} className={classes.linkBluePointer}>Learn more</a> about
                    Events invites.
                </>, revocationRestricted ? [{ title: 'Ok' },] : [{ title: 'Cancel' }, {
                    title: 'Revoke connection', action: this.handleRevokeAction, color: 'secondary'
                }]);
        }
    };

    private onSelectTees = (eventOrRound: EventBase, teeMen?: Tee, teeWomen?: Tee) => {
        const { contactData } = this.state;
        if (!contactData.gender) {
            return;
        }
        const golferTee = contactData.gender == 'female' ? teeWomen : teeMen;
        if (!golferTee) {
            return;
        }
        const eventTee = contactData.gender == 'female' ? eventOrRound.teeWomen : eventOrRound.teeMen;
        const isDefault = golferTee?.id === eventTee?.id;
        if (isDefault) {
            delete contactData.roundTees[eventOrRound.id];
        } else {
            contactData.roundTees[eventOrRound.id] = golferTee;
        }
        this.setState({ contactData });
    };

    private onHandicapOptionChanged = (handicapOption: HandicapOption) => this.setState({ handicapOption });

    private setHandicapIndexById = async (handicapId: string): Promise<void> => {
        const url = makeParameterizedGetRequestUrl(Urls.handicapGHINRequestUrl, 'handicapId', [handicapId]);
        const lastSearchedHandicapId = handicapId;
        try {
            const response = await axios.get(url, defaultAxiosRequestConfig);
            if (response.status === 200 && response.data?.golfersInfo) {
                const golfersInfo = response.data.golfersInfo;
                const golferInfo = golfersInfo[handicapId] as USGAGolferInfo;
                if (golferInfo != null && isValidHCPIndex(golferInfo.handicapIndex)) {
                    const { contactData } = this.state;
                    contactData.handicapIndex = toHandicapIndexStr(golferInfo.handicapIndex);
                    if (golferInfo.homeCity && !contactData.homeCourseOrCity) {
                        contactData.homeCourseOrCity = golferInfo.homeCity;
                    }
                    this.setState({
                        handicapIdStatus: 'success', contactData, loadingIdFromUSGA: false,
                        lastSearchedHandicapId, lastFoundHandicapIndexUSGA: contactData.handicapIndex
                    });
                    return;
                }
            }
            this.setState({ handicapIdStatus: 'failed', loadingIdFromUSGA: false, lastSearchedHandicapId });
        } catch (err) {
            console.log(errMsg((err)));
            this.setState({ handicapIdStatus: 'failed', loadingIdFromUSGA: false, lastSearchedHandicapId });
        }
    };

    private onHandicapIdLookup = async () => {
        const { handicapId } = this.state.contactData;
        if (handicapId && handicapId.length > 0) {
            this.setState({ loadingIdFromUSGA: true });
            await this.setHandicapIndexById(handicapId);
        }
    };

    private handleClose = (_uiEvent: string, reason: string) => {
        if ('backdropClick' === reason) {
            return;
        }
        this.props.handleClose();
    };

    private setNotifyAfterAdding = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ notifyAfterAdding: event.target.checked });

    private setPaymentDoc = (doc: Backend.DocumentSnapshot<DocumentData, DocumentData>) => {
        if (doc.exists()) {
            const paymentInfo = Backend.fromEntity<PaymentInfo>(doc);
            this.setState({ paymentInfo });
        }
    };

    render() {
        const { open, event, classes, actionMode, initialContact } = this.props;
        const headerName = actionMode === 'register' ? 'REGISTRATION'
            : actionMode === 'edit' ? 'EDIT GOLFER INFO'
                : actionMode === 'add' ? 'ADD GOLFER' : 'CONFIRM GOLFER INFO';
        return <>
            <XSMobileDialog open={open} onClose={this.handleClose}
                onKeyDown={e => processEnterKey(e, this.handleSubmitButton)}>
                <DialogAppBar appBarStyle={{ padding: 16, backgroundColor: '#5893c7' }}>
                    <Typography className={classes.bigHeaderFontStyle}>
                        {headerName}
                        <IconButton sx={{ float: 'right' }} onClick={this.props.handleClose} color="inherit" size="large">
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <Typography
                        className={`${classes.contactDialogEventName} ${classes.smallTextLabel}`}>{event.name}</Typography>
                </DialogAppBar>
                <DialogContent>
                    <this.MainComponent />
                </DialogContent>
                <DialogActions>
                    <this.ActionsComponent />
                </DialogActions>
                <Hidden smUp>
                    <Spacing height={24} />
                </Hidden>
            </XSMobileDialog>
            {initialContact?.paymentId && <FirebaseDocComponent
                docReference={Backend.eventPaymentDoc(event.id, initialContact.paymentId)}
                onDoc={this.setPaymentDoc} />}
        </>;
    }

    public ActionsComponent = () => {
        const { event, initialContact, actionMode, deleteFromEvent, handleClose } = this.props;
        const { contactData, notifyAfterAdding } = this.state;
        const canDelete = Boolean(initialContact && initialContact.id && deleteFromEvent);
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        return <>
            <Box width={8} height={1} />
            {canDelete &&
                <AppButton
                    style={{ width: isXs ? 'calc(48%)' : 'initial' }}
                    onClick={this.handleRemove}
                    color="info">
                    Delete
                </AppButton>}
            {canDelete && <span style={{ flex: '1 1 0%' }} />}
            <AppButton
                style={{ width: isXs ? 'calc(48%)' : 'initial' }}
                onClick={handleClose}
                color="info">
                Cancel
            </AppButton>
            <AppButton style={{
                width: isXs ? (actionMode === 'edit' ? 'calc(48%)' : '100%') : 'initial',
                marginLeft: isXs ? 0 : '10px'
            }}
                onClick={() => this.handleSubmitButton()} color="secondary"
                disabled={!contactData.lastName || !contactData.gender || (actionMode == 'add' && notifyAfterAdding && !isValidOrEmptyEmail(contactData.email))}>
                {actionMode === 'register' ? event.paymentSettings?.enabled ? 'Pay and register' : 'Register' :
                    actionMode === 'add' ? (notifyAfterAdding && contactData.email) ? 'Add and notify via email' : 'Add golfer' :
                        actionMode === 'confirm' ? 'Confirm' : 'Save'}
            </AppButton>
            <Box width={8} height={1} />
        </>;
    }

    public MainComponent = () => {
        const { event, actionMode, tees } = this.props;
        const { contactData, linkedFromApp } = this.state;
        const multiday = event.type === 'multiday';
        const fieldsDescription = this.getFieldsDescription();
        const actionModeEdit = actionMode != 'confirm' && actionMode != 'register';
        return (
            <div style={{ display: 'flex' }}>
                <Hidden mdDown>
                    <span style={{ minWidth: 100 }}>
                        <EditableAvatar url={contactData.avatar} handleAvatarChange={this.handleAvatarChange} />
                    </span>
                </Hidden>
                <span>
                    <div style={{ display: 'flex' }}>
                        <Grid container spacing={1}>
                            <Grid item sm={11} md={6}><TextField {...fieldsDescription.firstName} /></Grid>
                            <Grid item sm={11} md={6}><TextField {...fieldsDescription.lastName} /></Grid>
                            <Grid item sm={11} md={6}><TextField {...fieldsDescription.email} /></Grid>
                            <Hidden mdUp>
                                <Grid item xs={11}><this.NotifyGolferComponent /></Grid>
                            </Hidden>
                            <Grid item sm={11} md={6}><this.SelectGenderComponent /></Grid>
                            <Hidden mdDown>
                                <Grid item xs={12}><this.NotifyGolferComponent /></Grid>
                            </Hidden>
                        </Grid>
                        <Hidden mdUp>
                            <span style={{ minWidth: 100 }}>
                                <EditableAvatar url={contactData.avatar} handleAvatarChange={this.handleAvatarChange} />
                            </span>
                        </Hidden>
                    </div>
                    <this.HandicapComponent fieldsDescription={fieldsDescription} />
                    {actionModeEdit &&
                        <div>
                            <Grid container spacing={1}>
                                {!multiday &&
                                    <Grid item xs={4}>
                                        <SelectTeesComponent
                                            open
                                            tees={tees}
                                            variant="dropDownList"
                                            eventOrRound={event}
                                            onSelectTees={this.onSelectTees}
                                            selectedGolfers={[toGolfer(contactData, event)]}
                                        />
                                    </Grid>}
                                <Grid item xs={4}>
                                    <this.PaymentComponent fieldsDescription={fieldsDescription} />
                                </Grid>
                            </Grid>
                        </div>}
                    {actionModeEdit && linkedFromApp &&
                        <this.RevokeComponent />}
                    <Spacing />
                    {actionModeEdit && multiday &&
                        <this.SelectRoundTeesComponent />}
                    <Spacing />
                    <this.ShowMoreComponent fieldsDescription={fieldsDescription} />
                    <Spacing />
                </span>
            </div>
        );
    };

    private PaymentComponent = ({ fieldsDescription }: { fieldsDescription: FieldsDescription }) => {
        const { event, actionMode, initialContact, sendEmail } = this.props;
        const { contactData, paymentInfo } = this.state;
        if (!event.paymentSettings?.enabled || !initialContact) {
            return undefined;
        }
        return <>
            {initialContact.paymentId ? <TextField {...fieldsDescription.feePaid} /> :
                <FormControl variant="standard" margin="dense" fullWidth>
                    <InputLabel id="select-entry-fee-status" shrink>Entry fee</InputLabel>
                    <PaidStatusSelectionComponent
                        feePaid={contactData.feePaid}
                        contactEmail={contactData.email && emailFormat.test(contactData.email) ? contactData.email : undefined}
                        styles={{ textAlign: 'left', padding: '4px 0 5px' }}
                        disableUnderline={false}
                        horizontalPopupPlacement="center"
                        handleSendMessage={actionMode === 'add' ? undefined : async () =>
                            await sendEmail?.([initialContact], EmailVariant.eventUpcomingPayment)}
                        handleFeePaidStatusUpdate={this.setFeePaidStatus} />
                </FormControl>}
            {(contactData.feePaidDate || paymentInfo?.timeStampUTC) && <Typography style={{
                lineHeight: '15.95px', paddingLeft: 10, textAlign: 'left', fontSize: 11,
                color: AppColors.webGrey
            }}>Updated {formatDateUniversal((contactData.feePaidDate ?? paymentInfo?.timeStampUTC)!,
                isUSGolfCourse(event.course) ? 'MMMM-DD-YYYY' : 'DD-MMMM-YYYY')}
            </Typography>}
        </>;
    }

    private RevokeComponent = () => {
        const { classes, initialContact } = this.props;
        return (
            <div className={classes.connectedWithApp}>
                <Spacing />
                <img src={Urls.connectedPhoneIcon} alt={''} />
                Connected with the golfer's Golf Pad app.
                <a href={Urls.revokeConnectionSupportLink} className={classes.linkBluePointer}>Learn more</a>
                <AppButton
                    color="info"
                    disabled={!initialContact?.id}
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    onClick={this.handleRevoke}>
                    Revoke
                </AppButton>
            </div>
        );
    }

    private SelectRoundTeesComponent = () => {
        const { rounds, tees } = this.props;
        const { contactData } = this.state;
        const gender = contactData.gender || '';
        let customTees = 0;
        rounds.forEach(eventOrRound => {
            const golferTee = contactData.roundTees[eventOrRound.id];
            const eventTee = contactData.gender == 'female' ? eventOrRound.teeWomen : eventOrRound.teeMen;
            if (golferTee && golferTee.id !== eventTee?.id) {
                customTees++;
            }
        });
        return (
            <Accordion sx={{ backgroundColor: AppColors.webGrey100, position: 'unset', border: '2px solid ' + AppColors.webGrey200 }} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
                    Play from {customTees > 0 ? 'custom' : gender === 'female' ? `women's default` : `men's default`} tees
                    &nbsp;
                    <InfoIconTooltip
                        placement="right"
                        title={<>
                            You can select a different tee for this golfer than the default tees set for the rest of the field.<br />
                            <b>This tee selection will remain</b> if you decide to change the tournament default tees.
                        </>}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {rounds.map(eventOrRound =>
                            <Grid key={eventOrRound.id} item xs={12} sm={6}>
                                <SelectTeesComponent
                                    open
                                    tees={tees}
                                    variant="dropDownList"
                                    eventOrRound={eventOrRound}
                                    onSelectTees={this.onSelectTees}
                                    selectedGolfers={[toGolfer(contactData, eventOrRound)]} />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }

    private NotifyGolferComponent = () => {
        const { actionMode, classes } = this.props;
        const { contactData, notifyAfterAdding } = this.state;
        if (actionMode !== 'add') {
            return undefined;
        }
        const toolTipText = (
            <span>
                An email will be sent with a link to the Portal page and an app invitation. <br />
                The golfer can add the event to the Golf Pad app, to view the schedule & standings and post live scores.
            </span>
        );
        return (
            <FormControlLabel
                style={{ margin: 2 }}
                label={<Typography style={{ color: contactData.email ? AppColors.webBlack : AppColors.webGrey300 }}
                    className={classes.basicLabel}>
                    Notify the golfer via email
                    <InfoIconTooltip
                        offset1={-7}
                        style={{ marginLeft: 6 }}
                        title={toolTipText}
                        placement={'bottom-start'}
                        leaveTouchDelay={4000}
                    />
                </Typography>}
                control={<Checkbox
                    color="secondary"
                    disabled={!contactData.email}
                    onChange={this.setNotifyAfterAdding}
                    checked={notifyAfterAdding}
                />}
            />
        );
    };

    private SelectHandicapComponent = () => {
        const { classes, hasPro } = this.props;
        const { handicapOption } = this.state;
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        return <>
            <Typography className={classes.boldLabel}
                style={{ marginTop: 8, float: 'left' }}>Handicap</Typography>
            <FormControl
                fullWidth
                margin="dense"
                variant="standard"
                sx={{ flexDirection: isXs ? 'column' : 'row' }}
            >
                <TypedFormRadioLabel
                    value="Enter Manually"
                    currentValue={handicapOption}
                    label={<Typography
                        style={{ color: AppColors.webBlack }}
                        className={classes.basicLabel}>
                        Enter manually
                    </Typography>}
                    handleChange={this.onHandicapOptionChanged}
                    style={{ /*marginRight: 20, marginLeft: 0*/ }}
                />
                <TypedFormRadioLabel
                    value="USGA"
                    currentValue={handicapOption}
                    disabled={!hasPro}
                    label={<Typography
                        style={{ color: AppColors.webBlack }}
                        className={classes.basicLabel}>
                        Look up by USGA® Handicap ID
                    </Typography>}
                    handleChange={this.onHandicapOptionChanged}
                    style={{ /*marginLeft: 0*/ }}
                />
            </FormControl>
            {!hasPro &&
                <ProNotice>
                    Access to the Handicap Index® by USGA® Handicap ID is available only for the Events Pro
                </ProNotice>}
        </>;
    }

    private USGAComponent = ({ fieldsDescription }: { fieldsDescription: FieldsDescription }) => {
        const { handicapIdStatus, loadingIdFromUSGA, lastFoundHandicapIndexUSGA } = this.state;
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField {...fieldsDescription.handicapId} />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        textFieldProps={{
                            label: 'Handicap Index®',
                            InputProps: {
                                readOnly: true,
                                disableUnderline: true,
                                style: { color: handicapIdStatus === 'success' ? '#2C2C2C' : AppColors.webGrey300 },
                                startAdornment: loadingIdFromUSGA ? <CircularProgress color="secondary" size="1.2em" /> : undefined,
                            },
                            InputLabelProps: { shrink: true, },
                            value: loadingIdFromUSGA ? '' : `${handicapIdStatus === 'success' || handicapIdStatus === 'fromMemory' ? lastFoundHandicapIndexUSGA : 0}`
                        }}
                    />
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={4}>
                        <img src={usgaHandicapDataLabelVertical} alt="" />
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <img src={usgaHandicapDataLabelHorizontal} alt="" />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    private ManualHandicapComponent = ({ fieldsDescription }: { fieldsDescription: FieldsDescription }) => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField {...fieldsDescription.handicapIndex} />
                </Grid>
            </Grid>
        );
    }

    private HandicapComponent = ({ fieldsDescription }: { fieldsDescription: FieldsDescription }) => {
        const { handicapOption } = this.state;
        const showUSGAUI = this.showUSGAUI();
        return <>
            {showUSGAUI && <>
                <this.SelectHandicapComponent />
            </>}
            {showUSGAUI && handicapOption === 'USGA' ?
                <this.USGAComponent fieldsDescription={fieldsDescription} /> :
                <this.ManualHandicapComponent fieldsDescription={fieldsDescription} />}
        </>;
    }

    private SelectGenderComponent = () => {
        const { classes, event } = this.props;
        const { genderBlurred, contactData } = this.state;
        const gender = contactData.gender || '';
        const highlightGenderWithRed = genderBlurred && gender === '';
        return <>
            <FormControl
                variant="standard"
                margin="dense"
                fullWidth
                onBlur={_e => this.setState({ genderBlurred: true })}
                style={{
                    flexDirection: 'row',
                    marginBottom: 0,
                    maxWidth: 215,
                    color: highlightGenderWithRed ? AppColors.errorColor : AppColors.webBlack
                }}>
                <InputLabel className={classes.basicLabel} shrink
                    sx={{ color: highlightGenderWithRed ? AppColors.errorColor : AppColors.webGrey }}>
                    Gender (for tee selection)
                </InputLabel>
                <TypedFormRadioLabel
                    currentValue={gender}
                    value="male" label="Male"
                    disabled={event.eventGender === 'women'}
                    handleChange={this.handleGenderChange}
                    className={classes.formSelector} />
                <TypedFormRadioLabel
                    currentValue={gender}
                    value="female" label="Female"
                    disabled={event.eventGender === 'men'}
                    handleChange={this.handleGenderChange}
                    className={classes.formSelector} />
            </FormControl>
            {highlightGenderWithRed && <div style={{ float: 'left', marginLeft: 10 }}>
                <FormHelperText error margin="dense">Required field</FormHelperText>
            </div>}
        </>;
    }

    private ShowMoreComponent = ({ fieldsDescription }: { fieldsDescription: FieldsDescription }) => {
        return (
            <Accordion sx={{ backgroundColor: AppColors.webGrey100, position: 'unset', border: '2px solid ' + AppColors.webGrey200 }} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
                    Optional (home course, phone, notes)
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField {...fieldsDescription.homeCourseOrCity} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField keepError {...fieldsDescription.phone} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField {...fieldsDescription.notes} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    private getFieldsDescription(): FieldsDescription {
        const { contactData, handicapIdStatus, lastSearchedHandicapId } = this.state;
        const { classes, register } = this.props;
        const tfCommon = {
            onChange: this.handleFieldChange,
            InputLabelProps: { shrink: true } as InputLabelProps
        };
        const common = {
            register
        };
        function quote(s?: string | number) {
            return s == null ? '' : s;
        }
        return {
            lastName: {
                textFieldProps: {
                    id: 'lastName',
                    label: 'Last name',
                    value: quote(contactData.lastName),
                    ...tfCommon
                },
                rules: [required, maxLength(50)],
                ...common
            },
            firstName: {
                textFieldProps: {
                    id: 'firstName',
                    label: 'First name',
                    onBlur: this.handleNameBlur,
                    value: quote(contactData.firstName),
                    autoFocus: true,
                    ...tfCommon
                },
                rules: [maxLength(50)],
                ...common
            },
            email: {
                textFieldProps: {
                    id: 'email',
                    label: 'Email',
                    value: quote(contactData.email),
                    ...tfCommon
                },
                rules: [emailValidation, maxLength(50)],
                ...common
            },
            handicapId: {
                textFieldProps: {
                    id: 'handicapId',
                    label: 'Handicap ID', // <Typography style={{ minWidth: 90 }}></Typography>,
                    value: quote(contactData.handicapId),
                    placeholder: '123456',
                    helperText: handicapIdStatus === 'failed' ? <Typography className={classes.incorrectHandicapId}>Incorrect handicap ID</Typography> : undefined,
                    InputProps: {
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                disabled={!contactData.handicapId || handicapIdStatus === 'failed' || handicapIdStatus === 'fromMemory'}
                                tabIndex={-1} size="large" onClick={this.onHandicapIdLookup}>
                                {handicapIdStatus === 'success' ? <img src={Urls.checkMarkBlueIcon} alt="" /> :
                                    <SearchIcon />}
                            </IconButton>
                        </InputAdornment>,
                        className: handicapIdStatus === 'failed' ? classes.handicapByIdError : undefined
                    },
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                        tfCommon.onChange(event);
                        if (lastSearchedHandicapId !== event.target.value) {
                            contactData.handicapId = undefined;
                            this.setState({ handicapIdStatus: 'unset' });
                        }
                    },
                    InputLabelProps: tfCommon.InputLabelProps
                },
                ...common
            },
            handicapIndex: {
                textFieldProps: {
                    id: 'handicapIndex',
                    label: 'Handicap', // <Typography style={{ minWidth: 80 }}>Handicap</Typography>,
                    placeholder: '0',
                    value: quote(contactData.handicapIndex),
                    /*style: {
                        maxWidth: 100
                    },*/
                    ...tfCommon
                },
                rules: [handicap],
                ...common
            },
            homeCourseOrCity: {
                textFieldProps: {
                    id: 'homeCourseOrCity',
                    InputProps: {
                        endAdornment: <InfoIconTooltip
                            placement="bottom-start"
                            title={"If there are two golfers with the same first and last name in the event, the system will rely on this field to tell them apart."}
                            style={{ marginBottom: 4 }} offset1={-13} />
                    },
                    label: 'Home course or city',
                    value: quote(contactData.homeCourseOrCity),
                    ...tfCommon
                },
                rules: [homeCourseOrCity, maxLength(20)],
                ...common
            },
            phone: {
                textFieldProps: {
                    id: 'phone',
                    label: 'Phone number',
                    style: {
                        /*maxWidth: isXs ? 190 : 200,
                        float: isXs ? 'left' : 'right'*/
                    },
                    value: quote(contactData.phone), ...tfCommon
                },
                rules: [maxLength(20)],
                ...common
            },
            notes: {
                textFieldProps: {
                    id: 'notes',
                    label: 'Notes',
                    placeholder: 'Pairing or team requests, etc.',
                    value: quote(contactData.notes), ...tfCommon
                },
                rules: [maxLength(200)],
                ...common
            },
            feePaid: {
                textFieldProps: {
                    id: 'feePaid',
                    label: 'Entry fee',
                    style: {
                        /*maxWidth: isXs ? 240 : 215*/
                    },
                    value: 'Paid',
                    inputProps: {
                        readOnly: true
                    },
                    InputProps: {
                        endAdornment: <img className={classes.connectedPhoneIcon} src={Urls.iconPayPalLogo} alt="" />
                    }
                }
            }
        };
    }
}

export const handicap: ValidationRule<string> = {
    valid: function(value?: string) {
        const v = parseHandicap(value);
        return isValidHCPIndex(v);
    },
    text: 'Valid golfer index is +99 to 99.'
};

export default withStyles(styles)(withForm(ContactDialog));
