import * as React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Event, ContactDetails, EventData } from '../../../types/EventTypes';
import { fullName, getSameNameGolfersIds } from '../../../contact/Contact';
import { withProgress } from '../../../util/ProgressPromise';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import GridSelector, { GridItem } from '../../../common/GridSelector';
import { changeGolfersHiddeness } from '../../Event';

const compareGridItems = (a: GolferGridItem, b: GolferGridItem) => a.name.localeCompare(b.name);

class GolferGridItem implements GridItem {
    name: string;
    contact: ContactDetails;
    homeCourseOrCity?: string;
    constructor(contact: ContactDetails, homeCourseOrCity?: string) {
        this.contact = contact;
        this.homeCourseOrCity = homeCourseOrCity;
        this.name = fullName(contact);
    }
    id = () => this.contact.id;
    isDisabled = () => false;
    getBadge = () => '';
    getLabel = () => (
        <React.Fragment>
            {this.name}
            <div style={{ fontSize: '0.6rem' }}>
                {this.homeCourseOrCity ? ` (${this.homeCourseOrCity})` : ''}
            </div>
        </React.Fragment>
    );
}

function createGolferGridItem(contact: ContactDetails, sameNameGolfersIdsSet: Set<string>) {
    return new GolferGridItem(contact, contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? contact.homeCourseOrCity : undefined);
}

interface Props {
    open: boolean;
    event: Event;
    eventData: EventData;
    hiddenGolfersMode: boolean;
    handleAddGolfers: (contacts: Array<ContactDetails>) => void;
    handleCancel: () => void;
}

interface State {
    selectedCount: number;
    showHiddenGolfersDialog: boolean;
}

class AddGolfersFromRosterDialog extends React.Component<Props, State> {
    readonly state: State;
    readonly gridSelector: React.RefObject<GridSelector>;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCount: 0,
            showHiddenGolfersDialog: false
        };
        this.gridSelector = React.createRef();
    }

    private selectAll = () => this.gridSelector.current?.selectAll();
    private selectNone = () => this.gridSelector.current?.selectNone();
    private handleCancel = () => this.props.handleCancel();
    private handleSelected = (_: Array<GridItem>, __: boolean) =>
        this.setState({ selectedCount: this.gridSelector.current?.selectedCount() ?? 0 });

    private handleAdd = () => {
        if (!this.gridSelector.current) {
            return;
        }
        const { handleAddGolfers } = this.props;
        const { roster } = this.props.eventData;
        const { selected } = this.gridSelector.current.state;
        const contacts = Array.from(roster.values()).filter(g => selected.has(g.id));
        handleAddGolfers(contacts);
    };

    private changeHiddeness = (reveal: boolean) => {
        if (!this.gridSelector.current) {
            return;
        }
        const { event, handleCancel, hiddenGolfersMode } = this.props;
        const { roster } = this.props.eventData;
        const { selected } = this.gridSelector.current.state;
        const contacts = Array.from(roster.values()).filter(g => selected.has(g.id));
        this.selectNone();
        withProgress(changeGolfersHiddeness(event, contacts, reveal)).then(() => {
            if (hiddenGolfersMode) {
                handleCancel();
            }
        });
    };

    private getRosterContacts = (): Array<ContactDetails> => {
        const { eventData, event } = this.props;
        const { roster } = eventData;
        return Array.from(roster.values())
            .filter(c => !((event.eventGender === 'men' && c.gender === 'female') || event.eventGender === 'women' && c.gender === 'male'));
    };

    render() {
        const { event, open, hiddenGolfersMode, eventData } = this.props;
        const { showHiddenGolfersDialog, selectedCount } = this.state;
        const { golfersAggregated } = eventData;
        const rosterContacts = this.getRosterContacts();
        const sameNameGolfersIdsSet = getSameNameGolfersIds(rosterContacts);
        const label = hiddenGolfersMode ? 'Hidden golfers' : 'Add from past events';
        const filteredContacts = rosterContacts.filter(c => Boolean(c.hidden) === hiddenGolfersMode && !golfersAggregated.has(c.id));
        const contactItems = filteredContacts.map(c => createGolferGridItem(c, sameNameGolfersIdsSet)).sort(compareGridItems);
        const hiddenContactsCount = rosterContacts.reduce((acc, cur) => acc += cur.hidden ? 1 : 0, 0);
        const emptyStatus = 'No recent golfers yet';
        return <>
            <XSMobileDialog fullWidth={!hiddenGolfersMode} open={open} onClose={this.handleCancel} maxWidth={'lg'}>
                <DialogAppBar label={label} close={this.handleCancel} />
                <DialogActions style={{ padding: 20, paddingBottom: 4 }}>
                    <AppButton color="secondary" onClick={this.selectAll}>Select all</AppButton>
                    <AppButton color="info" onClick={this.selectNone}>Select none</AppButton>
                    <span style={{ flex: '1 1 0%' }} />
                    <Typography variant="body1">{contactItems.length} total, {selectedCount} selected</Typography>
                </DialogActions>
                <DialogContent>
                    {contactItems.length > 0 &&
                        <GridSelector ref={this.gridSelector} items={contactItems} handleSelected={this.handleSelected} />}
                    {contactItems.length === 0 &&
                        <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                </DialogContent>
                {hiddenGolfersMode && <DialogActions>
                    <AppButton color="info" onClick={this.handleCancel}>Cancel</AppButton>
                    <AppButton color="secondary" disabled={selectedCount === 0} onClick={() => this.changeHiddeness(true)}>Show selected</AppButton>
                </DialogActions>}
                {!hiddenGolfersMode && <DialogActions>
                    <AppButton color="info" disabled={selectedCount === 0} onClick={() => this.changeHiddeness(false)}>Hide selected</AppButton>
                    <AppButton color="info" disabled={hiddenContactsCount === 0} onClick={() => this.setState({ showHiddenGolfersDialog: true })}>Show hidden</AppButton>
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton color="info" onClick={this.handleCancel}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleAdd}>Save</AppButton>
                </DialogActions>}
            </XSMobileDialog>
            {showHiddenGolfersDialog &&
                <AddGolfersFromRosterDialog
                    open={true}
                    event={event}
                    eventData={eventData}
                    hiddenGolfersMode={true}
                    handleAddGolfers={() => { }}
                    handleCancel={() => this.setState({ showHiddenGolfersDialog: false })} />}
        </>;
    }
}

export default AddGolfersFromRosterDialog;
